import React from 'react';
import { useNavigate } from 'react-router-dom';
import { WALLET_SETUP } from '../../router/routeNames';
import OnBoardingLogoHeader from '../../components/header/OnBoardingLogoHeader';
import Svg from '../../assets/svg';
import WalletPaymentMethodForm from '../../features/wallets/WalletPaymentMethodForm';

const WalletSetupPaymentMethod = () => {
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(WALLET_SETUP, { replace: true });
  };
  return (
    <div>
      <div className="flex-1 min-h-screen flex flex-col bg-white">
        <OnBoardingLogoHeader />

        {/* Progress Bar */}
        <div
          className="w-full h-1.5 bg-indigo-700 mb-6"
          style={{ width: '80%' }}
        />

        {/* Main Container */}
        <div className="lg:grid-cols-2 gap-y-8 lg:gap-x-20 flex-1 px-6 lg:px-16">
          <div className="flex flex-col h-full">
            {/* Back Button */}
            <div
              className="w-[80px] md:w-[100px] h-10 md:h-11 px-2 py-1 md:px-2.5 md:py-1.5 rounded-lg border border-gray-200 flex justify-center items-center gap-2 cursor-pointer mb-4 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              onClick={handleBackClick}
            >
              <div className="flex items-center gap-2">
                <img
                  src={Svg.back}
                  alt="Back Icon"
                  className="object-contain h-5 w-5 md:h-6 md:w-6"
                />
                <span className="text-indigo-500 text-sm font-bold">Back</span>
              </div>
            </div>

            {/* Content Container */}
            <div className="flex justify-center w-full">
              <div className="w-full max-w-lg px-4 lg:px-0">
                {/* Header Text */}
                <div className="flex flex-col items-center mt-5 md:mt-8">
                  <h1 className="text-center text-gray-900 text-2xl md:text-4xl font-bold leading-tight">
                    Add Payment Method
                  </h1>
                  <p className="text-center text-gray-400 mt-2 text-base md:text-lg font-medium">
                    We will use this payment method for recharge. You can always
                    change from settings.
                  </p>
                </div>

                {/* Center-Aligned Form */}
                <div className="w-full max-w-md mt-8 md:mt-10 justify-center mx-auto">
                  <WalletPaymentMethodForm />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="w-full h-24 flex items-end justify-center mt-6 mb-4 text-center">
          <div className="text-gray-500 text-xs font-medium">
            © 2024 JLRecharge, LLC. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletSetupPaymentMethod;
