import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import FirestorePayments from '../../api/firebase/firebase.payments';
import {
  PAYMENTS_LOADED_DONE,
  PAYMENTS_LOADED_FAILED,
  PAYMENTS_LOADED_PENDING,
  UNKNOWN,
} from './paymentState';

const paymentsAdapter = createEntityAdapter({
  selectId: (paymentMethod) => paymentMethod.uid,
});
const initialState = paymentsAdapter.getInitialState({
  status: UNKNOWN,
});

export const loadPayments = createAsyncThunk(
  'payments/load',
  async (userId) => {
    const payments = await FirestorePayments.LoadByUserId(userId);
    return payments;
  }
);

const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    paymentStatus(state, action) {
      state.status = action.payload;
    },
    paymentsAddMany: paymentsAdapter.addMany,
    paymentsAddOne: paymentsAdapter.addOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadPayments.pending, (state) => {
        state.status = PAYMENTS_LOADED_PENDING;
      })
      .addCase(loadPayments.rejected, (state) => {
        state.status = PAYMENTS_LOADED_FAILED;
      })
      .addCase(loadPayments.fulfilled, (state, action) => {
        state.status =
          action.payload !== null
            ? PAYMENTS_LOADED_DONE
            : PAYMENTS_LOADED_FAILED;
        if (action.payload !== null) {
          paymentsAdapter.setAll(action.payload);
        }
      });
  },
});

export const { paymentStatus, paymentsAddMany, paymentsAddOne } =
  paymentsSlice.actions;

export default paymentsSlice.reducer;

export const { selectAll: selectPayments, selectById: selectPaymentById } =
  paymentsAdapter.getSelectors((state) => state.payments);

export const selectPaymentId = createSelector(selectPayments, (payments) =>
  payments.map((payment) => payment.uid)
);
