import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PhoneNumberInput, {
  getCountryCallingCode,
  parsePhoneNumber
} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './phoneInput.css';
import Svg from '../../assets/svg';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

const PhoneInput = ({ errorMessage, onChange }) => {
  const [countrySelected, setCountrySelected] = useState('US');
  const user = useSelector((state) => state.user);
  const userData = get(user, 'data', {});
  const [phoneNumber, setPhoneNumber] = useState('');
  useEffect( () => {
    const getUserPhone = async () => {
      if (userData.phone) {
        const parsedNumber = parsePhoneNumber(userData.phone);
        if (parsedNumber) {
          setPhoneNumber(parsedNumber.nationalNumber);
          setCountrySelected(parsedNumber.country);
        }
      }
    };
    getUserPhone();
   
  }, [userData.phone]);

  const handleChange = (newValue) => {
    setPhoneNumber(newValue);
    if (newValue) {
      const countryCode = getCountryCallingCode(countrySelected);
      const phoneNumberWithCountryCode = `+${countryCode}${newValue}`;
      onChange(phoneNumberWithCountryCode);
    } else {
      onChange('');
    }
  };

  const handleCountryChange = (country) => {
    setCountrySelected(country || 'US');
    onChange('');
  };

  return (
    <div className="mb-6">
      <label
        htmlFor="phone"
        className="text-black text-lg font-medium leading-normal tracking-tight"
      >
        Phone Number
      </label>
      <div className="flex items-center bg-gray-100 py-2 px-4 rounded-md">
        <PhoneNumberInput
          onChange={handleChange}
          onCountryChange={handleCountryChange}
          defaultCountry={countrySelected}
          className="phoneInput hidden"
        />
        {countrySelected && (
          <span className="ml-3 text-gray-900">
            +{getCountryCallingCode(countrySelected)}
          </span>
        )}
        <img
          src={Svg.chevron}
          alt="Chevron Icon"
          className="ml-2 object-cover"
        />
        <div className="w-4 h-px rotate-90 bg-[#babbc2]"></div>
        <input
          type="text"
          value={phoneNumber}
          onChange={(e) => handleChange(e.target.value)}
          placeholder="Enter your number here"
          className={`ml-2 flex-1 bg-transparent placeholder-gray-400 rounded-md text-gray-900 border ${
            errorMessage ? 'border-red-500' : 'border-gray-100'
          }`}
        />
      </div>
      {errorMessage && (
        <p className="mt-1 text-red-600 text-sm">{errorMessage}</p>
      )}
    </div>
  );
};

PhoneInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  phone: PropTypes.string
};

export default PhoneInput;
