import { getFirestore, collection, getDocs } from 'firebase/firestore';

import firebaseInit from './firebase.index';

const app = firebaseInit();
const db = getFirestore(app);

export default class FirestoreRates {
  static async LoadAll() {
    try {
      const ratesCollection = [];
      const ratesSnapShot = await getDocs(collection(db, 'rates'));
      if (ratesSnapShot === null || ratesSnapShot.length === 0) {
        return ratesCollection;
      }

      ratesSnapShot.forEach((snapshot) => {
        ratesCollection.push(snapshot.data());
      });
      return ratesCollection;
    } catch (error) {
      return null;
    }
  }
}
