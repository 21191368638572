import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import FirestoreLogs from '../../api/firebase/firestore.logs';
import { FAILED, PENDING, SUCCESS, UNKNOWN } from './logStatus';

const logsAdapter = createEntityAdapter({
  selectId: (log) => log.uid,
});

const initialState = logsAdapter.getInitialState({
  status: UNKNOWN,
});

export const logsAdd = createAsyncThunk('logs/Add', async (logData) => {
  const log = await FirestoreLogs.Add(logData);
  return log;
});

const logsSlice = createSlice({
  name: 'logs',
  initialState,
  reducers: {
    logsAddMany: logsAdapter.addMany,
    logsStatus(state, action) {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logsAdd.pending, (state, action) => {
        state.status = PENDING;
        state.logs = action.payload;
      })
      .addCase(logsAdd.rejected, (state, action) => {
        state.status = FAILED;
        state.logs = action.payload;
      })
      .addCase(logsAdd.fulfilled, (state, action) => {
        state.status = SUCCESS;
        logsAdapter.addOne(state, action.payload);
      });
  },
});

export const { logsAddMany, logsStatus } = logsSlice.actions;

export default logsSlice.reducer;
