import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import FirebaseAuth from '../../api/firebase/firebase.auth';
import { UNKNOWN } from '../paymentMethods/paymentMethodState';
import {
  AUTHENTICATION_FAILED,
  AUTHENTICATION_PENDING,
  AUTHENTICATION_SUCESS,
  UNAUTHENTICATED,
} from './authState';
import { AuthCode } from './models';

const initialState = {
  status: UNAUTHENTICATED,
};

export const signIn = createAsyncThunk(
  'authentication/signIn',
  async (authData) => {
    const user = await FirebaseAuth.SignIn(authData);
    return user;
  }
);

export const signUp = createAsyncThunk(
  'authentication/signUp',
  async (authData) => {
    const user = await FirebaseAuth.SignUp(authData);
    return user;
  }
);

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    authenticationStatusUpdate(state, action) {
      state.status = action.payload;
    },
    authenticationMessage(state, action) {
      return {
        status: UNAUTHENTICATED,
        ...state,
        data: {
          ...state.data,
          message: action.payload,
        },
      };
    },
    authenticationAdd(state, action) {
      state.status = AUTHENTICATION_SUCESS;
      state.data = action.payload;
    },
    authenticationSignOut(state, action) {
      state.status = UNKNOWN;
      state.data = action.payload;
    },
    authenticationRoleAdd(state, action) {
      return {
        status: UNAUTHENTICATED,
        ...state,
        data: {
          ...state.data,
          role: action.payload,
        },
      };
    },
    authenticationEmailAdd(state, action) {
      return {
        status: UNAUTHENTICATED,
        ...state,
        data: {
          ...state.data,
          email: action.payload,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.status = AUTHENTICATION_PENDING;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.status = AUTHENTICATION_FAILED;
        state.data = action.payload;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.status =
          action.payload.status === AuthCode.SUCCESS
            ? AUTHENTICATION_SUCESS
            : AUTHENTICATION_FAILED;
        state.data = action.payload;
      })
      .addCase(signUp.pending, (state) => {
        state.status = AUTHENTICATION_PENDING;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.status = AUTHENTICATION_FAILED;
        state.data = action.payload;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.status =
          action.payload.status === AuthCode.SUCCESS
            ? AUTHENTICATION_SUCESS
            : AUTHENTICATION_FAILED;
        state.data = action.payload;
      });
  },
});

export const {
  authenticationStatusUpdate,
  authenticationMessage,
  authenticationAdd,
  authenticationSignOut,
  authenticationRoleAdd,
  authenticationEmailAdd,
} = authenticationSlice.actions;

export default authenticationSlice.reducer;
