import React from 'react';
import { useSelector } from 'react-redux';
import BusinessItem from './BusinessItem';
import BusinessState from './BusinessState';
import { selectBusinessIds } from './businessSlice';
import { BUSINESS_ADD } from '../../router/routeNames';

const BusinessCollection = () => {
  const businessIds = useSelector((state) => selectBusinessIds(state));
  const business = useSelector((state) => state.businesses);

  if (business?.ids?.length == 0) {
    return <BusinessState route={BUSINESS_ADD} />;
  }

  const renderBusiness =
    businessIds &&
    businessIds.map((businessId) => {
      return <BusinessItem key={businessId} id={businessId} />;
    });
  return (
    <div role="list" className="mt-5 flex flex-col lg:flex-row lg:space-x-4">
      {renderBusiness}
    </div>
  );
};

export default BusinessCollection;
