import React, { useState } from 'react';
import { isEmpty } from '../../utils/stringHelper';
import CountrySelector from '../recharge/CountrySelector';
import Input, { isPossiblePhoneNumber } from 'react-phone-number-input/input';
import { useDispatch } from 'react-redux';
import { filterPhoneAdd } from './filterSlice';

const PhoneNumberFilter = () => {
  const dispatch = useDispatch();
  const [phone, setPhone] = useState('');
  const [countryCallingCode, setCountryCallingCode] = useState('');

  const handleCountryChange = (data) => {
    setCountryCallingCode(data.callingCodes[0]);
  };

  const handlePhoneNumber = (data) => {
    if (isEmpty(data)) {
      return;
    }
    setPhone(data?.trim());
    const phoneWithCountryCode = countryCallingCode + data?.trim();
    if (isPossiblePhoneNumber(phoneWithCountryCode)) {
      setPhone(phoneWithCountryCode);
      dispatch(filterPhoneAdd(phoneWithCountryCode));
      return;
    }
  };

  return (
    <div>
      <div className="mt-1 flex rounded-md mx-2">
        <div className="relative flex items-stretch flex-grow focus-within:z-10">
          <CountrySelector onChange={handleCountryChange} />
          <div className="w-full">
            <Input
              className="mt-1 p-4 h-14 w-40 rounded-r-2xl border border-gray-300 
								 bg-white py-2 focus:border-indigo-500 
                                  focus:outline-none focus:ring-1 focus:ring-indigo-500 
                                  sm:text-base text-gray-600"
              placeholder="Phone number"
              onChange={handlePhoneNumber}
              value={phone}
              defaultCountry="CV"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhoneNumberFilter;
