import React, { useEffect, useState } from 'react';
import {
  CreditCardIcon,
  ExclamationCircleIcon,
  CheckCircleIcon,
  PhoneIcon,
} from '@heroicons/react/solid';
import {
  RECHARGE_COMPLETE,
  RECHARGE_PROCESS_PAYMENT,
  RECHARGE_PROCESS_PAYMENT_FAILED,
  RECHARGE_PROCESS_PAYMENT_SUCCESSFULLY,
  RECHARGE_PROCESS_TOPOP_INSUFICIENT_CREDIT,
  RECHARGE_PROCESS_TOPUP,
  RECHARGE_PROCESS_TOPUP_FAILED,
  RECHARGE_PROCESS_TOPUP_SUCCESSFULLY,
} from './rechargeStatus';
import { useDispatch, useSelector } from 'react-redux';
import {
  rechargeAmountReceivedAdd,
  rechargeAmountSentAdd,
  rechargeFeesAdd,
  rechargePhoneAdd,
  rechargeStatus,
  rechargeTotalAmountAdd,
  rechargeUserCommission,
} from './rechargeSlice';
import { useNavigate } from 'react-router-dom';
import { WALLET_RECHARGE } from '../../router/routeNames';
import { ROLES } from '../authentication/models';

const getProcessingIcon = (status) => {
  switch (status) {
    case RECHARGE_PROCESS_PAYMENT:
    case RECHARGE_PROCESS_PAYMENT_FAILED:
      return CreditCardIcon;
    case RECHARGE_PROCESS_PAYMENT_SUCCESSFULLY:
      return CheckCircleIcon;
    case RECHARGE_COMPLETE:
    case RECHARGE_PROCESS_TOPUP:
      return PhoneIcon;
    case RECHARGE_PROCESS_TOPOP_INSUFICIENT_CREDIT:
    case RECHARGE_PROCESS_TOPUP_FAILED:
      return ExclamationCircleIcon;
    case RECHARGE_PROCESS_TOPUP_SUCCESSFULLY:
      return CheckCircleIcon;
    default:
      return PhoneIcon;
  }
};

const RechargeProcessing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [ctaTitle, setCtaTitle] = useState('Done!');

  const recharge = useSelector((state) => state.recharge);

  let StatsIcon = getProcessingIcon(recharge?.status);

  useEffect(() => {
    if (recharge?.status === RECHARGE_PROCESS_PAYMENT) {
      setTitle('Payment');
      setDescription('Processing Payment');
      setCtaTitle('Processing');
    }

    if (recharge?.status === RECHARGE_PROCESS_PAYMENT_FAILED) {
      setTitle('Payment');
      setDescription('Processing Payment Failed.');
      setCtaTitle('Contact Us!');
    }

    if (recharge?.status === RECHARGE_PROCESS_PAYMENT_SUCCESSFULLY) {
      setTitle('Payment');
      setDescription('Processing Payment with Sucess');
    }

    if (recharge?.status === RECHARGE_PROCESS_TOPUP) {
      setTitle('Recharge');
      setDescription(`Recharging phone - ${recharge.phone}`);
      setCtaTitle('Processing');
    }

    if (recharge?.status === RECHARGE_PROCESS_TOPUP_FAILED) {
      setTitle('Failed');
      setDescription(
        `Failed Reacharge Phone: ${recharge.phone} - ${recharge.message?.error}.`
      );
      setCtaTitle('Try again!');
    }

    if (recharge?.status === RECHARGE_PROCESS_TOPUP_SUCCESSFULLY) {
      setTitle('Complete');
      setDescription(`Recharging ${recharge.phone} is complete`);
      setCtaTitle('Done!');
    }

    if (recharge?.status === RECHARGE_PROCESS_TOPOP_INSUFICIENT_CREDIT) {
      setTitle('Insucifient Credit!');
      setDescription('Add Credit to your wallet and try again.');
      const canAddCredit =
        user.data?.roles[ROLES.partner] || user.data?.roles[ROLES.manager];
      setCtaTitle(`${canAddCredit ? 'Add Credit' : 'Done'}`);
    }

    if (recharge?.status === RECHARGE_COMPLETE) {
      setTitle('Recharge');
      setDescription('Thank you for your business!');
      setCtaTitle('Done!');
    }
  }, [recharge?.status, recharge.phone]);

  const handleDone = () => {
    // Re-state to send new Request
    dispatch(rechargePhoneAdd(''));
    dispatch(rechargeAmountSentAdd(''));
    dispatch(rechargeAmountReceivedAdd(''));
    dispatch(rechargeTotalAmountAdd(''));
    dispatch(rechargeFeesAdd(''));
    dispatch(rechargeUserCommission(''));
    dispatch(rechargeStatus(RECHARGE_COMPLETE));
    if (ctaTitle === 'Add Credit') {
      navigate(WALLET_RECHARGE, { replace: true });
      return;
    }
  };

  return (
    <div className="flex flex-col justify-center h-96">
      <div className="flex flex-row justify-center">
        <StatsIcon
          className="h-16 w-16 text-blue-1000 mb-2 text-center"
          aria-hidden="true"
        />
      </div>
      <h3 className="mt-2 text-2xl font-medium text-gray-900 text-center">
        {title}
      </h3>
      <p className="mt-1 text-base text-gray-500 text-center mb-5">
        {description}
      </p>
      <div className="flex justify-center">
        <button
          onClick={handleDone}
          className="rounded-md w-48 justify-center inline-flex items-center px-6 mt-2 py-3 border border-transparent 
                        text-base font-medium shadow-sm text-white bg-indigo-600"
        >
          {ctaTitle}
        </button>
      </div>
    </div>
  );
};

export default RechargeProcessing;
