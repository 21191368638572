import React from 'react';
import AuthEmailVerified from '../../features/authentication/AuthEmailVerified';
import OnBoardingLogoHeader from '../../components/header/OnBoardingLogoHeader';

const EmailVerified = () => {
  return (
    <div>
      <OnBoardingLogoHeader />
      <div className="w-[48%] h-1.5 bg-indigo-700 max-w-[70%] sm:max-w-[70%] md:max-w-[70%]" />
      <AuthEmailVerified />
    </div>
  );
};

export default EmailVerified;
