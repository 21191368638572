import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FirestoreBusiness from '../../api/firebase/firestore.business';
import FirestoreUser from '../../api/firebase/firestore.user';
import FirestoreWallets from '../../api/firebase/firestore.wallets';
import { ROLES } from '../../features/authentication/models';
import {
  businessAddMany,
  businessAddOne,
  businessSelected,
  businessStatus,
  selectBusinessSelected,
} from '../../features/business/businessSlice';
import { BUSINESS_READY } from '../../features/business/businessStatus';
import { userAdd, userStatus } from '../../features/user/userSlice';
import { USER_READY } from '../../features/user/userStatus';
import { walletAdd, walletsStatus } from '../../features/wallets/walletSlice';
import { WALLETS_READY } from '../../features/wallets/walletStatus';
import {
  SPLASH,
  PERSONAL_INFORMATION,
  BUSINESS,
  BUSINESS_INFORMATION,
  WALLET_SETUP,
} from '../../router/routeNames';
import FirestorePaymentMethod from '../../api/firebase/firestore.paymentMethods';
import FirestoreProducts from '../../api/firebase/firebase.products';
import {
  productsAddMany,
  productsStatus,
} from '../../features/products/productsSlice';
import { PRODUCT_LOADED } from '../../features/products/productsStatus';
import { PROTECTED_VIEW_SUCCESS } from '../../features/protectedViews/protectedViewsStatus';
import FirestoreProtectedViews from '../../api/firebase/firebase.protectedViews';
import {
  protectedViewAdd,
  protectedViewsStatusUpdate,
} from '../../features/protectedViews/protectedViewsSlice';
import { isEmpty } from 'lodash';

const withBoostraApp = (Component) => () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const authentication = useSelector((state) => state.authentication);
  const business = useSelector((state) => selectBusinessSelected(state));
  const products = useSelector((state) => state.products);
  const protectedViews = useSelector((state) => state.protectedViews);
  useEffect(() => {
    async function boostrappApp() {
      const userData =
        user === undefined || user?.data === undefined
          ? await FirestoreUser.LoadById(authentication?.data?.uid)
          : user?.data;
      if (userData === undefined || userData === null) {
        navigate(SPLASH, { replace: true });
        return;
      }

      dispatch(userAdd(userData));
      dispatch(userStatus(USER_READY));

      // Navigate to User Personal Information
      if (!userData.isActive) {
        navigate(PERSONAL_INFORMATION, { replace: true });
        return;
      }

      let businessActive = null;
      const isAgentOrEmployee =
        userData.roles[ROLES.agent] || userData.roles[ROLES.employee];
      if (business === undefined) {
        const businessData = isAgentOrEmployee
          ? await FirestoreBusiness.LoadById(userData.businessId)
          : await FirestoreBusiness.LoadByOwnerId(userData.uid);
        if (isAgentOrEmployee && businessData !== null) {
          dispatch(businessAddOne(businessData));
          dispatch(businessSelected(businessData));
          dispatch(businessStatus(BUSINESS_READY));
          businessActive = businessData;
        } else if (businessData !== null && businessData.length > 0) {
          dispatch(businessAddMany(businessData));
          dispatch(businessStatus(BUSINESS_READY));
          businessActive = businessData[0];

          businessData.filter((b) => {
            if (b.selected) {
              businessActive = b;
            }
          });
          dispatch(businessSelected(businessActive));
        }
      } else {
        businessActive = business;
      }
      // Business still in review process
      if (!businessActive) {
        navigate(BUSINESS_INFORMATION, { replace: true });
        return;
      }

      // Function will help to migrate payment methods if the filed is wrong.
      await FirestorePaymentMethod.migratePaymentMethods(userData.uid);

      const wallet = isAgentOrEmployee
        ? await FirestoreWallets.GetById(businessActive.ownerId)
        : await FirestoreWallets.GetById(userData.uid, businessActive.uid);

      if (wallet !== undefined) {
        dispatch(walletAdd(wallet));
        dispatch(walletsStatus(WALLETS_READY));
      }

      // Wallet setup
      if (!userData.isWalletSetup) {
        navigate(WALLET_SETUP, { replace: true });
        return;
      }

      // Business no approved - Review
      if (!businessActive.isApproved) {
        navigate(BUSINESS);
        return;
      }

      if (products && products.status !== PRODUCT_LOADED) {
        // Load Products
        const products = await FirestoreProducts.LoadProducts();
        if (products && products.length > 0) {
          dispatch(productsAddMany(products));
          dispatch(productsStatus(PRODUCT_LOADED));
        }
      }
      if (protectedViews.status !== PROTECTED_VIEW_SUCCESS) {
        // Load Protected Views
        const protectedViews = await FirestoreProtectedViews.LoadById(
          userData.uid
        );
        if (!isEmpty(protectedViews)) {
          dispatch(protectedViewAdd(protectedViews));
          dispatch(protectedViewsStatusUpdate(PROTECTED_VIEW_SUCCESS));
        }
      }
    }

    boostrappApp();
  }, [user?.data, business]);
  return <Component />;
};

export default withBoostraApp;
