import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FirestoreWallets from '../../api/firebase/firestore.wallets';
import {
  WALLETS_ADD_PAYMENTMETHOD_FAILED,
  WALLETS_ADD_PAYMENTMETHOD_PENDING,
  WALLETS_FUND_ADD_FAILED,
  WALLETS_FUND_ADD_PENDING,
  WALLETS_READY,
  WALLETS_UNKNOWN,
  WALLETS_UPDATE_CARD_FAILED,
  WALLETS_UPDATE_CARD_PENDING,
} from './walletStatus';

const initialState = {
  status: WALLETS_UNKNOWN,
};

export const walletPaymentMethodAdd = createAsyncThunk(
  'wallet/paymentMethodAdd',
  async (paymentMethodData) => {
    const paymentMehod = await FirestoreWallets.PaymentMethodAdd(
      paymentMethodData
    );
    return paymentMehod;
  }
);

export const walletPaymentMethodDefault = createAsyncThunk(
  'wallet/walletPaymentMethodDefault',
  async (wallet) => {
    const walletDefaultId = await FirestoreWallets.SetDefatultPaymentMethod(
      wallet
    );
    return walletDefaultId;
  }
);

export const walletUpdate = createAsyncThunk(
  'wallet/update',
  async (walletData) => {
    const wallet = await FirestoreWallets.Update(walletData);
    return wallet;
  }
);

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    walletsStatus(state, action) {
      state.status = action.payload;
    },
    walletAdd(state, action) {
      state.status = WALLETS_READY;
      state.data = action.payload;
    },
    walletMessageAdd(state, action) {
      return { ...state, message: action.payload };
    },
    walletTitleAdd(state, action) {
      return { ...state, title: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(walletPaymentMethodAdd.pending, (state, action) => {
        state.status = WALLETS_ADD_PAYMENTMETHOD_PENDING;
        state.data = action.payload;
      })
      .addCase(walletPaymentMethodAdd.rejected, (state, action) => {
        state.status = WALLETS_ADD_PAYMENTMETHOD_FAILED;
        state.data = action.payload;
      })
      .addCase(walletPaymentMethodAdd.fulfilled, (state, action) => {
        state.status = WALLETS_READY;
        state.data = action.payload;
      })
      .addCase(walletPaymentMethodDefault.pending, (state, action) => {
        state.status = WALLETS_UPDATE_CARD_PENDING;
        state.data = action.payload;
      })
      .addCase(walletPaymentMethodDefault.rejected, (state, action) => {
        state.status = WALLETS_UPDATE_CARD_FAILED;
        state.data = action.payload;
      })
      .addCase(walletPaymentMethodDefault.fulfilled, (state, action) => {
        state.status = WALLETS_READY;
        state.data = action.payload;
      })
      .addCase(walletUpdate.pending, (state, action) => {
        state.status = WALLETS_FUND_ADD_PENDING;
        state.data = action.payload;
      })
      .addCase(walletUpdate.rejected, (state, action) => {
        state.status = WALLETS_FUND_ADD_FAILED;
        state.data = action.payload;
      })
      .addCase(walletUpdate.fulfilled, (state, action) => {
        state.status =
          action.payload === null ? WALLETS_FUND_ADD_FAILED : WALLETS_READY;
        state.data = action.payload;
      });
  },
});

export const { walletAdd, walletsStatus, walletMessageAdd, walletTitleAdd } =
  walletSlice.actions;

export default walletSlice.reducer;
