import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { selectProductById } from './productsSlice';
import { rechargeProductIdAdd } from '../recharge/rechargeSlice';
import { PRODUCT_TYPES } from '../../constant/variables';
import Svg from '../../assets/svg';

const ProductCardItem = ({ uid }) => {
  const dispatch = useDispatch();
  const product = useSelector((state) => selectProductById(state, uid));
  const recharge = useSelector((state) => state.recharge);
  const productId = get(recharge, 'productId', '');
  const isSelected = productId === uid || false;

  const handleSelectProduct = (product) => {
    const productUid = get(product, 'uid', '');
    dispatch(rechargeProductIdAdd(productUid));
  };
  return (
    <div
      className={`w-full md:w-36 h-12 pl-4 pr-4 py-4 ${
        isSelected ? 'bg-white' : 'bg-gray-200'
      } rounded-lg border ${
        isSelected ? 'border-[#4343d6]' : 'border-white'
      } flex items-start gap-2 cursor-pointer`}
      onClick={() => handleSelectProduct(product)}
    >
      <div className="w-4 flex justify-center items-center">
        <img
          src={
            product.productType === PRODUCT_TYPES.RECHARGE
              ? Svg.recharge
              : Svg.bundles
          }
          alt="product"
          className="w-4 h-4 object-contain"
        />
      </div>
      <div className="text-gray-900 text-xs font-bold leading-none tracking-tight ml-1">
        {product?.displayName}
      </div>
    </div>
  );
};

ProductCardItem.propTypes = {
  uid: PropTypes.string.isRequired,
};

export default ProductCardItem;
