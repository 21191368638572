import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  authenticationAdd,
  authenticationStatusUpdate
} from './authenticationSlice';
import { AUTHENTICATION_FAILED, AUTHENTICATION_SUCESS } from './authState';
import { isEmpty } from '../../utils/stringHelper';
import { AuthCode } from './models';
import { EMAIL_VERIFICATION, LOGIN, SPLASH } from '../../router/routeNames';
import { isValidEmail, isValidPassword } from '../../utils/regexHelper';
import FirebaseAuth from '../../api/firebase/firebase.auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import onboarding_logo from '../../assets/icons/onboarding_logo.png';
import LoaderStats from '../../components/loader/LoaderStats';
import { setRole } from '../user/userSlice';
import { PRIVACY_POLICY, TERMS_OF_SERVICE } from '../../constant/variables';
import { get } from 'lodash';
import CopyrightText from '../../components/atoms/CopyrightText';

const AuthEmail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const authentication = useSelector((state) => state.authentication);
  const authenticationData = get(authentication, 'data', {});

  const handleSignUp = async () => {
    try {
      setIsLoading(true);
      setErrorMessage('');

      if (isEmpty(email)) {
        setErrorMessage('Email is required.');
        return;
      }

      if (!isValidEmail(email)) {
        setErrorMessage('Invalid email format.');
        return;
      }

      if (isEmpty(password)) {
        setErrorMessage('Password is required.');
        return;
      }

      if (!isValidPassword(password)) {
        setErrorMessage(
          'Password must have at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character.'
        );
        return;
      }

      if (!isChecked) {
        setErrorMessage('You must accept the terms and conditions.');
        return;
      }
      const userRoles ={ 
        roles: {
          partner: true,
          manager: false,
          agent: false,
          employee: false
        }
      };
      const authData = {
        email: email,
        password: password,
      };
      const authResulted = await FirebaseAuth.SignUp(authData);
      if (authResulted?.status === AuthCode.FAILED) {
        setErrorMessage(authResulted.message);
        return;
      }
      if (!authResulted?.uid) {
        return;
      }
      const userData = {
        uid: authResulted?.uid,
        isActive: false,
        ...userRoles
      };
      dispatch(setRole(userData));
      if (authResulted.status === AuthCode.SUCCESS) {
        let userInfo = {
          ...authResulted,
          email: email,
          ...userRoles
        };
        dispatch(authenticationAdd(userInfo));
        dispatch(authenticationStatusUpdate(AUTHENTICATION_SUCESS));
      } else {
        setErrorMessage(authResulted.message);
        dispatch(authenticationStatusUpdate(AUTHENTICATION_FAILED));
      }
    } catch (error) {
      setErrorMessage('Unknown error. Contact support@jlrecharge.com');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (authentication === undefined) return;
    if (authentication.status !== AUTHENTICATION_SUCESS) return;
    if (authenticationData === undefined) return;
    if (authenticationData?.uid !== undefined) {
      navigate(SPLASH, { replace: true });
    }
    if (
      authentication.status === AUTHENTICATION_SUCESS &&
      !authenticationData?.emailVerified
    ) {
      navigate(EMAIL_VERIFICATION, { replace: true });
    }
  }, [authentication.status]);

  return (
    <div className="flex flex-col lg:flex-row bg-white">
      <div className="flex-1 p-4 lg:p-10">
        <div className="mx-auto w-full max-w-sm lg:max-w-md mt-10">
          <div className="flex justify-center">
            <h2 className="text-gray-900 text-[32px] sm:text-[36px] md:text-[40px] lg:text-[44px] font-black leading-[48px] sm:leading-[54px] md:leading-[60px] lg:leading-[64px]">
              Create your account
            </h2>
          </div>

          <div className="flex justify-center">
            <p className="text-center text-gray-500 text-lg font-medium leading-normal tracking-tight">
              Welcome! Please enter your details.
            </p>
          </div>

          <div className="flex-1">
            <div className="mt-3">
              <form action="#" method="POST" className="p-6">
                <div className="mb-6">
                  <label
                    htmlFor="email"
                    className="text-black text-lg font-medium leading-normal tracking-tight"
                  >
                    Email
                  </label>
                  <div className="flex">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Enter your email"
                      autoComplete="email"
                      required
                      value={email}
                      onChange={(data) => setEmail(data.target.value)}
                      className={`appearance-none block w-full px-3 py-4 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-[16px] bg-gray-100 ${
                        errorMessage ? 'border-red-500' : 'border-gray-200'
                      }`}
                    />
                  </div>
                </div>
                <div className="flex-1">
                  <label
                    htmlFor="password"
                    className="text-black text-lg font-medium leading-normal tracking-tight"
                  >
                    Password
                  </label>
                  <div className="mt-1 relative">
                    <input
                      id="password"
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Enter your password"
                      autoComplete="current-password"
                      required
                      value={password}
                      onChange={(data) => setPassword(data.target.value)}
                      className={`appearance-none block w-full px-3 py-4 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-[16px] bg-gray-100 ${
                        errorMessage ? 'border-red-500' : 'border-gray-200'
                      }`}
                    />
                    <div
                      className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <FontAwesomeIcon
                        icon={showPassword ? faEyeSlash : faEye}
                        className="h-5 w-5 text-gray-500"
                      />
                    </div>
                  </div>
                </div>
                {errorMessage && (
                  <p className="mt-1 text-red-600 text-sm">{errorMessage}</p>
                )}
                <div className="mt-4 flex items-center">
                  <div className="relative flex items-center">
                    <input
                      type="checkbox"
                      id="terms"
                      checked={isChecked}
                      onChange={() => setIsChecked(!isChecked)}
                      className="absolute opacity-0 h-6 w-6"
                    />
                    <div
                      className={`w-6 h-6 rounded-md border-2 cursor-pointer flex justify-center items-center ${
                        isChecked
                          ? 'border-indigo-500 bg-indigo-500'
                          : 'border-gray-200'
                      }`}
                      onClick={() => setIsChecked(!isChecked)}
                    >
                      {isChecked && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4 text-white"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      )}
                    </div>
                  </div>
                  <label
                    htmlFor="terms"
                    className="ml-2 text-gray-900 text-base font-medium leading-normal tracking-tight"
                  >
                    I accept the{' '}
                    <a
                      href={PRIVACY_POLICY}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="underline cursor-pointer"
                    >
                      Privacy Policy
                    </a>{' '}
                    &{' '}
                    <a
                      href={TERMS_OF_SERVICE}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="underline cursor-pointer"
                    >
                      Terms of Service
                    </a>
                    .
                  </label>
                </div>

                <div className="mt-8">
                  <button
                    type="button"
                    onClick={handleSignUp}
                    className="w-full flex justify-center py-4 px-4 rounded-xl text-lg font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    disabled={isLoading}
                  >
                    {isLoading ? <LoaderStats size="small" /> : 'Sign Up'}
                  </button>
                </div>
                <div className="flex items-center justify-center mt-3">
                  <span className="text-gray-500 text-base font-medium leading-normal tracking-tight">
                    Already have an account?
                  </span>
                  <Link
                    to={LOGIN}
                    className="font-medium text-indigo-600 hover:text-indigo-500 text-base leading-normal tracking-tight ml-1"
                  >
                    Sign In
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1 hidden lg:flex items-center justify-center max-w-full">
        <img
          src={onboarding_logo}
          alt="Login Visual"
          className="object-contain h-64 w-72 lg:h-[650px] lg:w-[700px]"
        />
      </div>
      <CopyrightText />
    </div>
  );
};

export default AuthEmail;
