import React, { useEffect, useState } from 'react';
import { CashIcon } from '@heroicons/react/solid';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  PAYMENTMETHODS_ADD_DONE,
  PAYMENTMETHODS_ADD_FAILED,
  PAYMENTMETHODS_ADD_PENDING,
  PAYMENTMETHODS_LOADED_PENDING,
} from '../paymentMethods/paymentMethodState';
import {
  WALLETS_FUND_ADD_FAILED,
  WALLETS_FUND_ADD_PENDING,
  WALLETS_FUND_ADD_SUCCESS,
  WALLETS_LOADING,
  WALLETS_READY,
} from './walletStatus';
import { useNavigate } from 'react-router-dom';
import { WALLET, WALLET_RECHARGE } from '../../router/routeNames';
import { walletsStatus } from './walletSlice';

const WalletProcessing = ({ route }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.wallet);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [ctaTtitle, setCtaTitle] = useState('');
  const [action, setAction] = useState(route);

  const handleCtaOnClick = () => {
    dispatch(walletsStatus(WALLETS_READY));
    navigate(action || route);
  };

  useEffect(() => {
    switch (wallet.status) {
      case PAYMENTMETHODS_ADD_DONE:
        setTitle('Success!');
        setDescription('Payment Method done sucessefuly');
        setCtaTitle('Done');
        break;
      case PAYMENTMETHODS_ADD_PENDING:
        setTitle('In Process');
        setDescription('We saving your Payment Method');
        setCtaTitle('Processing...');
        break;
      case PAYMENTMETHODS_ADD_FAILED:
        setTitle('Failed!');
        setDescription('An issue occour while adding Payment Method.');
        setCtaTitle('Back');
        break;
      case PAYMENTMETHODS_LOADED_PENDING:
        setTitle('Loading');
        setDescription('Loading Payment Methods.');
        setCtaTitle('Processing...');
        break;
      case WALLETS_FUND_ADD_PENDING:
        setTitle('In Progress');
        setDescription('Adding Fund to Wallet');
        setCtaTitle('Processing...');
        break;
      case WALLETS_FUND_ADD_FAILED:
        setTitle(wallet?.title || 'Failed');
        setDescription(wallet.message || 'Failed add Fund.');
        setCtaTitle('Back');
        setAction(WALLET_RECHARGE);
        break;
      case WALLETS_FUND_ADD_SUCCESS:
        setTitle('Success!');
        setDescription('Your Fund is applied to your Wallet.');
        setCtaTitle('Done');
        break;
      case WALLETS_LOADING:
        setTitle('Loading');
        setDescription('Loading Wallet Data');
        break;
      default:
        setTitle('Wallet Fund');
        setDescription('Adding Fund to your Wallet');
        setCtaTitle('Back');
    }
  }, [wallet.status]);

  return (
    <div className="relative z-0 flex overflow-hidden bg-white rounded-3xl flex-1 py-10">
      <div className="text-center py-5 border-2 border-dashed border-gray-25 xl:p-20 mx-8 mb-8 w-full flex flex-col">
        <CashIcon
          className="text-blue-1000 mt-5 h-16 w-16 self-center"
          aria-hidden="true"
        />
        <h3 className="mt-2 text-xl font-medium text-gray-900">{title}</h3>
        <p className="mt-1 text-base text-gray-500">{description}</p>
        <div className="flex justify-center my-5">
          <button
            onClick={handleCtaOnClick}
            className="py-3 px-6 text-lg font-bold bg-blue-1000 rounded-2xl text-white border-dotted bottom-2 border-blue-1000"
          >
            {ctaTtitle}
          </button>
        </div>
      </div>
    </div>
  );
};

WalletProcessing.defaultProps = {
  route: WALLET,
};

WalletProcessing.propTypes = {
  route: PropTypes.string,
};
export default WalletProcessing;
