export class PaymentIntent {
  constructor(user, wallet, amount) {
    if (user.stripeId === undefined) {
      throw new Error(
        'User Missing StripeID - Contact support: support@jlrecharge.com'
      );
    }

    if (wallet.paymentMethodId === undefined) {
      throw new Error(
        'Wallet Missing PaymentMethod ID - Contact support: support@jlrecharge.com'
      );
    }
    const stripeAmout = (Number(amount) * 100).toFixed(2);
    this.amount = Number(stripeAmout);
    this.currency = user.currencyCode?.toLowerCase() || 'usd';
    this.customer = user.stripeId;
    this.statement_descriptor = 'Wallet Fund';
    this.description = 'Wallet Fund';
    this.statement_descriptor_suffix = 'JLR-1.8';
    this.payment_method = wallet.paymentMethodId;
    this.setup_future_usage = 'off_session';
    this.automatic_payment_methods = {
      enabled: true,
    };
    if (user.email) {
      this.receipt_email = user.email;
    }
    this.confirm = false;
  }
}
