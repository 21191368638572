import React, { useRef } from 'react';
import PropTypes from 'prop-types';

const PasscodeInput = ({ passcode, onChangeText, handleKeyDownChange }) => {
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && !passcode[index] && index > 0) {
      inputRefs[index - 1].current.focus();
      const newPasscode = [...passcode];
      newPasscode[index - 1] = '';
      handleKeyDownChange(newPasscode);
    }
  };

  const handleChange = (index, value) => {
    if (!/^\d?$/.test(value)) return;
    const newPasscode = [...passcode];
    newPasscode[index] = value;
    if (value && index < 3) {
      inputRefs[index + 1].current.focus();
    }
    onChangeText(newPasscode);
  };
  return (
    <div>
      <div className="flex justify-center space-x-5">
        {passcode.map((digit, index) => (
          <input
            key={index}
            ref={inputRefs[index]}
            type="text"
            maxLength="1"
            className="w-14 h-14 bg-gray-200 text-gray-400 border-gray-200 text-center text-lg rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-transparent"
            value={digit ? '*' : ''}
            onChange={(e) => handleChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(index, e)}
          />
        ))}
      </div>
    </div>
  );
};
PasscodeInput.propTypes = {
  passcode: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChangeText: PropTypes.func.isRequired,
  handleKeyDownChange: PropTypes.func.isRequired
};

export default PasscodeInput;
