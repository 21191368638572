import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { Combobox } from '@headlessui/react';
import FirestoreCountries from '../../api/firebase/firestore.countries';
import {
  countryAddMany,
  countryStatus,
  selectCountryReceiverActive,
} from '../countries/countriesSlice';
import { COUNTRY_LOADED } from '../countries/countryStatus';
import { isEmpty } from '../../utils/stringHelper';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const CountrySelector = ({ onChange }) => {
  const recharge = useSelector((state) => state.recharge);
  const countries = useSelector((state) => selectCountryReceiverActive(state));

  const dispatch = useDispatch();
  const [query, setQuery] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(undefined);

  const filteredCountries =
    query === ''
      ? countries
      : countries.filter((country) => {
          return country.name.toLowerCase().includes(query.toLowerCase());
        });

  const selectCountries = async (data) => {
    setSelectedCountry(data);
    onChange(data);
  };

  useEffect(() => {
    async function loadCountries() {
      // Load all Active Countries:
      const countries = await FirestoreCountries.LoadActive();
      if (countries !== undefined && countries.length > 0) {
        dispatch(countryAddMany(countries));
        dispatch(countryStatus(COUNTRY_LOADED));
        setSelectedCountry(countries[0]);
        // Get Country Selected
        if (isEmpty(recharge.receiverCountryCode)) return;

        const selectedReceiverCountry = countries.filter(
          (country) => country.isoName === recharge.receiverCountryCode
        );
        if (
          selectedReceiverCountry !== undefined &&
          selectedReceiverCountry.length > 0
        ) {
          setSelectedCountry(selectedReceiverCountry[0]);
        }
      }
    }
    loadCountries();
  }, []);

  const renderCountries = () => {
    return (
      <Combobox as="div" value={selectedCountry} onChange={selectCountries}>
        <div className="relative mt-1">
          <div className="flex flex-row">
            <div className="p-3 absolute">
              <img
                src={selectedCountry?.flag}
                alt=""
                className="h-8 w-8 object-cover rounded-md"
              />
            </div>
            <div className="w-full">
              <Combobox.Input
                className="w-full p-8 h-14 rounded-l-md border-y-2 border-l-2 border-gray-100 bg-white py-4 pl-12 shadow-sm 
                                focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-lg font-medium text-gray-600"
                onChange={(event) => setQuery(event.target.value)}
                displayValue={(country) =>
                  country?.isoName + ' ' + country?.callingCodes[0]
                }
              />
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                <SelectorIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Combobox.Button>
            </div>
          </div>
          {filteredCountries.length > 0 && (
            <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredCountries.map((country) => (
                <Combobox.Option
                  key={country.isoName}
                  value={country}
                  className={({ active }) =>
                    classNames(
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                      active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      <div className="flex items-center">
                        <img
                          src={country.flag}
                          alt=""
                          className="h-6 w-6 flex-shrink-0 rounded-full"
                        />
                        <span
                          className={classNames(
                            'ml-3 truncate',
                            selected && 'font-semibold'
                          )}
                        >
                          {country.isoName}
                        </span>
                      </div>

                      {selected && (
                        <span
                          className={classNames(
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-white' : 'text-indigo-600'
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>
    );
  };

  return selectedCountry !== undefined ? renderCountries() : '';
};

export default CountrySelector;
