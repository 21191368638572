export const walletData = {
  amount: 0,
  paymentMethodId: '',
  balanceAmount: 400,
  minBalance: 100,
  minBalanceOptions: {
    0: 100,
    2: 150,
    3: 250
  },
  balanceAmountOptions: {
    0: 100,
    2: 200,
    3: 400,
    4: 600,
    5: 800,
    6: 1000
  },
  autoRecharge: false
};
