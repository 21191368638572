import React, { useEffect, useState } from 'react';
import { UserCircleIcon } from '@heroicons/react/solid';
import { useDispatch, useSelector } from 'react-redux';
import {
  USER_READY,
  USER_UPDATE_FAILED,
  USER_UPDATE_PENDING,
  USER_UPDATE_SUCCESS,
} from './userStatus';
import { userStatus } from './userSlice';

const UserState = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const handleDone = () => {
    dispatch(userStatus(USER_READY));
  };

  useEffect(() => {
    switch (user.status) {
      case USER_UPDATE_FAILED:
        setTitle('Failed!');
        setDescription('Failed Update user');
        return;
      case USER_UPDATE_PENDING:
        setTitle('Pending');
        setDescription('Update user in Progress.');
        return;
      case USER_UPDATE_SUCCESS:
        setTitle('Complete!');
        setDescription('User update with success.');
        return;
      default:
        setTitle('Loading User');
        setDescription('User Data will be ready soon.');
    }
  }, [user.status]);

  return (
    <div className="relative z-0 overflow-hidden bg-white rounded-3xl py-10 w-full">
      <div className="text-center py-5 border-2 border-dashed border-gray-25 xl:p-20 mx-8 mb-8 flex flex-col justify-center">
        <UserCircleIcon
          className="text-blue-1000 mt-5 h-16 w-16 self-center"
          aria-hidden="true"
        />
        <h3 className="mt-2 text-lg font-medium text-gray-900">{title}</h3>
        <p className="mt-1 text-sm text-gray-500 mb-10">{description}</p>
        <button
          onClick={handleDone}
          type="button"
          className="self-center w-28 text-center items-center px-2.5 py-1.5 border border-transparent text-base font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Done
        </button>
      </div>
    </div>
  );
};

export default UserState;
