import { get } from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PAYMENTS } from '../../router/routeNames';
import { protectedViewsCreate } from './protectedViewsSlice';
import Svg from '../../assets/svg';
import PasscodeInput from '../../components/inputs/PasscodeInput';

const ProtectedViewUnLockModel = () => {
  const protectedViews = useSelector((state) => state.protectedViews);
  const protectedViewData = get(protectedViews, 'data', {});
  const dispatch = useDispatch();
  const [passcode, setPasscode] = useState(['', '', '', '']);
  const [error, setError] = useState('');
  const isPasscodeComplete = passcode.every((digit) => digit !== '');

  const handleValueChange = (value) => {
    setPasscode(value);
    setError('');
  };

  const handleUnlock = () => {
    const code = passcode.join('');
    if (code !== protectedViewData.code) {
      setError(
        'Invalid passcode. Please try again or contact support at support@jlrecharge.com.'
      );
      return;
    }
    const protectedData = {
      ...protectedViewData,
      isLocked: false,
    };
    dispatch(protectedViewsCreate(protectedData));
    setError('');
  };

  if (
    protectedViewData &&
    protectedViewData?.name !== PAYMENTS &&
    !protectedViewData?.isLocked
  ) {
    return null;
  }
  return (
    <div className="flex flex-col items-center justify-center  bg-white px-4 sm:px-6 lg:px-8">
      <img src={Svg.protected_stars} alt="Lock" className="mx-auto" />
      <h2 className="text-lg text-gray-900 text-[24px] sm:text-[28px] font-semibold text-center mb-[23px]">
        Enter 4-digit Passcode
      </h2>
      <div className="mb-[25px]">
        <PasscodeInput
          passcode={passcode}
          onChangeText={handleValueChange}
          handleKeyDownChange={(value) => setPasscode(value)}
        />
      </div>
      {error && (
        <p className="text-red-500 text-center w-full max-w-xs">{error}</p>
      )}
      <div
        onClick={isPasscodeComplete ? handleUnlock : null}
        className={`w-full max-w-xs h-[60px] mt-4 p-2.5 rounded-xl flex justify-center items-center border transition-all duration-300 ease-in-out
        ${
          isPasscodeComplete
            ? 'bg-[#4343d6] border-[#4343d6] cursor-pointer hover:bg-[#3333b3]'
            : 'bg-gray-200 border-gray-400 cursor-not-allowed'
        }`}
      >
        <span
          className={`text-lg font-bold tracking-tight ${
            isPasscodeComplete ? 'text-white' : 'text-black'
          }`}
        >
          Unlock
        </span>
      </div>
    </div>
  );
};

export default ProtectedViewUnLockModel;
