import { ServerApi } from './serverApi';

export const getFeesByAmount = async (request) => {
  try {
    const url = `${process.env.REACT_APP_API_HOST}/api/${process.env.REACT_APP_VERSION}/fees/amount`;
    const serverApi = new ServerApi();
    const fees = await serverApi.Post(url, request);
    return fees;
  } catch (error) {
    return null;
  }
};