import logo from './logo.svg';
import envelop from './envelop.svg';
import email_verified from './email_verified.svg';
import chevron from './chevron.svg';
import user_profile from './user_profile.svg';
import back from './back.svg';
import user_commission from './user_commission.svg';
import user_contact_globally from './user_contact_globally.svg';
import user_operators from './user_operators.svg';
import wallet from './wallet.svg';
import wallet_setup_done from './wallet_setup_done.svg';
import recharge from './recharge.svg';
import bundles from './bundles.svg';
import payment_lock from './payment_lock.svg';
import protected_stars from './protected_stars.svg';
const Svg = {
    logo,
    protected_stars,
    payment_lock,
    bundles,
    recharge,
    wallet_setup_done,
    wallet,
    envelop,
    email_verified,
    chevron,
    user_profile,
    back,
    user_commission,
    user_contact_globally,
    user_operators
};  
export default Svg;