import { get } from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PAYMENTS } from '../../router/routeNames';
import {
  protectedViewModelStatus,
  protectedViewsCreate,
} from './protectedViewsSlice';
import Svg from '../../assets/svg';
import PasscodeInput from '../../components/inputs/PasscodeInput';
import { PROTECTED_VIEWS_UNKNOWN } from './protectedViewsStatus';

const ProtectedViewCreateLockModel = () => {
  const protectedViews = useSelector((state) => state.protectedViews);
  const protectedViewData = get(protectedViews, 'data', {});
  const dispatch = useDispatch();
  const [passcode, setPasscode] = useState(['', '', '', '']);
  const [error, setError] = useState('');
  const isPasscodeComplete = passcode.every((digit) => digit !== '');
  const user = useSelector((state) => state.user);

  const handleValueChange = (value) => {
    setPasscode(value);
    setError('');
  };

  const handleGenerate = () => {
    const code = passcode.join('');
    setError('');
    const userId = get(user, 'data.uid', '');
    const protectedData = {
      ...protectedViewData,
      name: PAYMENTS,
      code: code,
      isLocked: true,
      userId: userId,
    };
    dispatch(protectedViewsCreate(protectedData));
    handleClose();
  };

  const handleClose = () => {
    dispatch(protectedViewModelStatus(PROTECTED_VIEWS_UNKNOWN));
  };

  if (protectedViewData?.name !== PAYMENTS && protectedViewData?.isLocked) {
    return null;
  }
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white w-full max-w-md p-6 rounded-xl shadow-lg relative">
        <button
          onClick={handleClose}
          className="absolute top-3 right-3 text-gray-600 hover:text-gray-800 text-xl"
        >
          &times;
        </button>

        <div className="flex flex-col items-center">
          <img src={Svg.protected_stars} alt="Lock" className="mx-auto" />
          <h2 className="text-lg text-gray-900 text-[24px] sm:text-[28px] font-semibold text-center mb-[23px]">
            Create 4-digit Passcode
          </h2>
          <div className="mb-[25px]">
            <PasscodeInput
              passcode={passcode}
              onChangeText={handleValueChange}
              handleKeyDownChange={(value) => setPasscode(value)}
            />
          </div>
          {error && (
            <p className="text-red-500 text-center w-full max-w-xs">{error}</p>
          )}
          <div
            onClick={isPasscodeComplete ? handleGenerate : null}
            className={`w-full max-w-xs h-[60px] mt-4 p-2.5 rounded-xl flex justify-center items-center border transition-all duration-300 ease-in-out
            ${
              isPasscodeComplete
                ? 'bg-[#4343d6] border-[#4343d6] cursor-pointer hover:bg-[#3333b3]'
                : 'bg-gray-200 border-gray-400 cursor-not-allowed'
            }`}
          >
            <span
              className={`text-lg font-bold tracking-tight ${
                isPasscodeComplete ? 'text-white' : 'text-black'
              }`}
            >
              Lock
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProtectedViewCreateLockModel;
