import { useDispatch } from 'react-redux';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  authenticationAdd,
  authenticationStatusUpdate
} from './authenticationSlice';
import {
  AUTHENTICATION_FAILED,
  AUTHENTICATION_INPROGRESS,
  AUTHENTICATION_SUCESS
} from './authState';
import { isEmpty } from '../../utils/stringHelper';
import { AuthCode, AuthData } from './models';
import { EMAIL_VERIFICATION, FORGOT_PASSWORD, SIGNUP } from '../../router/routeNames';
import { isValidEmail, isValidPassword } from '../../utils/regexHelper';
import FirebaseAuth from '../../api/firebase/firebase.auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import onboarding_logo from '../../assets/icons/onboarding_logo.png';
import LoaderStats from '../../components/loader/LoaderStats';
import CopyrightText from '../../components/atoms/CopyrightText';
import { getAuth } from 'firebase/auth';
import FirestoreUser from '../../api/firebase/firestore.user';

const SignIn = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSignIn = async () => {
    try {
      setIsLoading(true);
      dispatch(authenticationStatusUpdate(AUTHENTICATION_INPROGRESS));
      setErrorMessage('');

      if (isEmpty(email)) {
        setErrorMessage('Email is required.');
        setIsLoading(false);
        return;
      }

      if (!isValidEmail(email)) {
        setErrorMessage('Invalid email format.');
        setIsLoading(false);
        return;
      }

      if (isEmpty(password)) {
        setErrorMessage('Password is required.');
        setIsLoading(false);
        return;
      }

      if (!isValidPassword(password)) {
        setErrorMessage(
          'Password must have at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character.'
        );
        setIsLoading(false);
        return;
      }

      const authData = new AuthData({
        email: email,
        password: password
      });
      let authResulted = await FirebaseAuth.SignIn(authData);
      if (authResulted.status === AuthCode.SUCCESS) {
        const auth = getAuth().currentUser;

         // Update Email Verified
         if (auth?.emailVerified !== authResulted.emailVerified) {
          authResulted = await FirestoreUser.Update({
            uid: auth.uid,
            emailVerified: auth?.emailVerified
          });
        }

        if (!auth?.emailVerified) {
          navigate(EMAIL_VERIFICATION, { replace: true });
        } 

        dispatch(authenticationAdd(authResulted));
        dispatch(authenticationStatusUpdate(AUTHENTICATION_SUCESS));
      } else {
        setErrorMessage(authResulted.message);
        dispatch(authenticationStatusUpdate(AUTHENTICATION_FAILED));
      }
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col lg:flex-row bg-white min-h-screen relative">
      <div className="flex-1 p-4 lg:p-10">
        <div className="mx-auto w-full max-w-sm md:max-w-md mt-10">
          <div className="flex justify-center">
            <h2 className="text-gray-900 text-3xl sm:text-[36px] md:text-[40px] font-black leading-[48px] sm:leading-[54px] md:leading-[60px] lg:leading-[64px]">
              Login to your account
            </h2>
          </div>

          <div className="flex justify-center mt-2">
            <p className="text-center text-gray-500 text-base sm:text-lg font-medium leading-normal tracking-tight">
              Welcome back! Please enter your details.
            </p>
          </div>

          <div className="flex-1">
            <div className="mt-3">
              <form action="#" method="POST" className="p-6">
                <div className="mb-6">
                  <label
                    htmlFor="email"
                    className="text-black text-base sm:text-lg font-medium leading-normal tracking-tight"
                  >
                    Email
                  </label>
                  <div className="flex">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Enter your email"
                      autoComplete="email"
                      required
                      value={email}
                      onChange={(data) => setEmail(data.target.value)}
                      className={`appearance-none block w-full px-3 py-4 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-[16px] bg-gray-100 ${
                        errorMessage ? 'border-red-500' : 'border-gray-200'
                      }`}
                    />
                  </div>
                </div>

                <div className="flex-1 mb-4">
                  <label
                    htmlFor="password"
                    className="text-black text-base sm:text-lg font-medium leading-normal tracking-tight"
                  >
                    Password
                  </label>
                  <div className="mt-1 relative">
                    <input
                      id="password"
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Enter your password"
                      autoComplete="current-password"
                      required
                      value={password}
                      onChange={(data) => setPassword(data.target.value)}
                      className={`appearance-none block w-full px-3 py-4 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-[16px] bg-gray-100 ${
                        errorMessage ? 'border-red-500' : 'border-gray-200'
                      }`}
                    />
                    <div
                      className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <FontAwesomeIcon
                        icon={showPassword ? faEyeSlash : faEye}
                        className="h-5 w-5 text-gray-500"
                      />
                    </div>
                  </div>
                </div>

                <div className="flex justify-end mt-3">
                  <Link
                    to={FORGOT_PASSWORD}
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Forgot password?
                  </Link>
                </div>

                {errorMessage && (
                  <p className="mt-1 text-red-600 text-sm">{errorMessage}</p>
                )}

                <div className="mt-8">
                  <button
                    type="button"
                    onClick={handleSignIn}
                    className="w-full flex justify-center py-4 px-4 rounded-xl text-lg font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    disabled={isLoading}
                  >
                    {isLoading ? <LoaderStats size="small" /> : 'Sign In'}
                  </button>
                </div>

                <div className="flex items-center justify-center mt-3">
                  <span className="text-gray-500 text-base font-medium leading-normal tracking-tight">
                    Don’t have an account?
                  </span>
                  <Link
                    to={SIGNUP}
                    className="font-medium text-indigo-600 hover:text-indigo-500 text-base leading-normal tracking-tight ml-1"
                  >
                    Sign Up
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="flex-1 hidden lg:flex justify-end lg:justify-center p-4">
        <img
          src={onboarding_logo}
          alt="Login Visual"
          className="object-contain h-64 w-72 lg:h-[650px] lg:w-[700px]"
        />
      </div>
      <div className="absolute bottom-[70px] w-full">
        <CopyrightText />
      </div>
    </div>
  );
};

export default SignIn;
