import {
  collection,
  doc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where
} from 'firebase/firestore';

import firebaseInit from './firebase.index';
import { get } from 'lodash';

const app = firebaseInit();
const db = getFirestore(app);

export default class FirestoreProtectedViews {
  /**
   * Loads a protected view by user Id.
   * @param {string} userId - The userId of the protected view to load.
   * @returns {Promise<Object|null>} The data of the protected view if found, otherwise null.
   */
  static async LoadById(userId) {
    try {
      const protectedViewQuery = query(collection(db, 'protectedViews'), where('userId', '==', userId));
      const querySnapshot = await getDocs(protectedViewQuery);
      if (!querySnapshot.empty) {
        return querySnapshot.docs[0].data();
      }
      return null;
    } catch (error) {
     return null;
    }
  }

  static async CreateOrUpdate(protectedViewData) {
    try {
      const uid = get(protectedViewData, 'uid');
  
      const protectedViewRef = uid 
        ? doc(db, 'protectedViews', uid)
        : doc(collection(db, 'protectedViews')); 
  
      if (!uid) {
        protectedViewData.uid = protectedViewRef.id; 
      }
      await setDoc(protectedViewRef, protectedViewData, { merge: true });
      return protectedViewData;
    } catch (error) {
      return null;
    }
  }
}
