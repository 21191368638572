import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DropDown from '../../components/atoms/DropDown';
import LoaderStats from '../../components/loader/LoaderStats';
import { get } from 'lodash';
import {
  businessAdd,
  businessUpdate,
  selectBusinessSelected,
} from './businessSlice';
import { useNavigate } from 'react-router-dom';
import { WALLET_SETUP } from '../../router/routeNames';
import { userUpdate } from '../user/userSlice';

const BusinessInformationForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    streetAddress: '',
    state: '',
    city: '',
    postalCode: '',
    country: '',
    submit: '',
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const countriesEntities = useSelector((state) => state.countries.entities);
  const countries = Object.values(countriesEntities);
  const user = useSelector((state) => state.user);
  const userData = get(user, 'data', {});
  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.wallet);
  const navigate = useNavigate();
  const business = useSelector((state) => selectBusinessSelected(state));

  useEffect(() => {
    if (business) {
      setFormData({
        name: business.name,
        streetAddress: business.street,
        state: business.state,
        city: business.city,
        postalCode: business.zipCode,
        country: {
          name: business.countryName,
          isoName: business.countryCode,
          currencyCode: business.currencyCode,
        },
      });
    }
  }, [business]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: '',
    });
  };

  const handleCountrySelect = (country) => {
    setFormData({
      ...formData,
      country,
    });
    setErrors({
      ...errors,
      country: '',
    });
  };

  const validateForm = () => {
    const newErrors = {};
    // Check required fields
    if (!formData.name) newErrors.name = 'Full Name is required.';
    if (!formData.streetAddress)
      newErrors.streetAddress = 'Street Address is required.';
    if (!formData.state) newErrors.state = 'State is required.';
    if (!formData.city) newErrors.city = 'City is required.';
    if (!formData.postalCode) {
      newErrors.postalCode = 'Postal Code is required.';
    } else if (formData.postalCode.length !== 5) {
      newErrors.postalCode = 'Postal Code must be exactly 5 characters.';
    }

    if (!formData.country) newErrors.country = 'Country is required.';

    return newErrors;
  };

  const handleSubmit = (e) => {
    try {
      setIsLoading(true);
      e.preventDefault();
      const validationErrors = validateForm();

      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
      } else {
        const businessData = {
          ownerId: userData.uid,
          name: formData.name,
          city: formData.city,
          street: formData.streetAddress,
          countryName: formData.country.name,
          zipCode: formData.postalCode,
          state: formData.state,
          isApproved: false,
          walletId: wallet?.data?.uid || userData?.uid,
          countryCode: formData.country.isoName,
          currencyCode: formData.country.currencyCode,
        };

        const userInfo = {
          uid: userData.uid,
          countryName: formData.country.name,
          countryCode: formData.country.isoName,
          currencyCode: formData.country.currencyCode,
          street: formData.streetAddress,
          zipCode: formData.postalCode,
          state: formData.state,
          city: formData.city,
        };
        if (!business) {
          dispatch(businessAdd(businessData));
        } else {
          const businessId = get(business, 'uid', '');
          dispatch(businessUpdate({ ...businessData, uid: businessId }));
        }
        dispatch(userUpdate(userInfo));
        navigate(WALLET_SETUP, { replace: true });
      }
    } catch (error) {
      setErrors({ ...errors, submit: error.message });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <form
      onSubmit={handleSubmit}
      className="w-full max-w-lg md:max-w-2xl lg:max-w-4xl mx-auto px-4"
    >
      <h1 className="mt-3 text-gray-900 text-3xl md:text-4xl font-bold leading-tight">
        Business Information
      </h1>
      <p className="text-gray-500 text-md md:text-lg font-medium leading-normal tracking-tight mb-8">
        Please add your personal information to continue.
      </p>

      {/* Full Name Input */}
      <div className="mb-6">
        <label
          htmlFor="name"
          className="block text-black text-md md:text-lg font-medium leading-normal tracking-tight mb-2"
        >
          Business Name*
        </label>
        <input
          id="name"
          name="name"
          type="text"
          placeholder="Ex: JLRecharge"
          value={formData.name}
          onChange={handleInputChange}
          className={`w-full px-3 py-4 border rounded-md shadow-sm placeholder-gray-400 focus:ring-indigo-500 focus:border-indigo-500 bg-gray-100 ${
            errors.name ? 'border-red-500' : 'border-gray-200'
          }`}
        />
        {errors.name && (
          <p className="mt-1 text-red-600 text-sm">{errors.name}</p>
        )}
      </div>

      {/* Street Address Input */}
      <div className="mb-6">
        <label
          htmlFor="streetAddress"
          className="block text-black text-md md:text-lg font-medium leading-normal tracking-tight mb-2"
        >
          Street Address*
        </label>
        <input
          id="streetAddress"
          name="streetAddress"
          type="text"
          placeholder="Ex: 982 Main Street"
          value={formData.streetAddress}
          onChange={handleInputChange}
          className={`w-full px-3 py-4 border rounded-md shadow-sm placeholder-gray-400 focus:ring-indigo-500 focus:border-indigo-500 bg-gray-100 ${
            errors.streetAddress ? 'border-red-500' : 'border-gray-200'
          }`}
        />
        {errors.streetAddress && (
          <p className="mt-1 text-red-600 text-sm">{errors.streetAddress}</p>
        )}
      </div>

      {/* State and City Inputs */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        <div>
          <label
            htmlFor="state"
            className="text-md md:text-lg font-medium leading-normal"
          >
            State*
          </label>
          <input
            id="state"
            name="state"
            type="text"
            placeholder="Ex: MA"
            value={formData.state}
            onChange={handleInputChange}
            className={`w-full px-3 py-4 border mt-2 rounded-md shadow-sm placeholder-gray-400 focus:ring-indigo-500 focus:border-indigo-500 bg-gray-100 ${
              errors.state ? 'border-red-500' : 'border-gray-200'
            }`}
          />
          {errors.state && (
            <p className="text-red-600 text-sm">{errors.state}</p>
          )}
        </div>

        <div>
          <label
            htmlFor="city"
            className="text-md md:text-lg font-medium leading-normal"
          >
            City*
          </label>
          <input
            id="city"
            name="city"
            type="text"
            placeholder="Ex: Boston"
            value={formData.city}
            onChange={handleInputChange}
            className={`w-full px-3 py-4 border mt-2 rounded-md shadow-sm placeholder-gray-400 focus:ring-indigo-500 focus:border-indigo-500 bg-gray-100 ${
              errors.city ? 'border-red-500' : 'border-gray-200'
            }`}
          />
          {errors.city && <p className="text-red-600 text-sm">{errors.city}</p>}
        </div>
      </div>

      {/* Postal Code and Country Selector */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        <div>
          <label
            htmlFor="postalCode"
            className="text-md md:text-lg font-medium leading-normal"
          >
            Postal Code*
          </label>
          <input
            id="postalCode"
            name="postalCode"
            type="text"
            placeholder="Ex: 02118"
            value={formData.postalCode}
            maxLength={5}
            onChange={handleInputChange}
            className={`w-full px-3 py-4 mt-2 border rounded-md shadow-sm placeholder-gray-400 focus:ring-indigo-500 focus:border-indigo-500 bg-gray-100 ${
              errors.postalCode ? 'border-red-500' : 'border-gray-200'
            }`}
          />
          {errors.postalCode && (
            <p className="text-red-600 text-sm">{errors.postalCode}</p>
          )}
        </div>

        <div>
          <label
            htmlFor="country"
            className="text-md md:text-lg font-medium leading-normal"
          >
            Country*
          </label>
          <div className="mt-2">
            <DropDown
              options={countries}
              selectedItem={formData?.country}
              onSelectItem={handleCountrySelect}
              placeholder="Ex: United States"
              error={errors.country}
            />
          </div>
          {errors.country && (
            <p className="text-red-600 text-sm">{errors.country}</p>
          )}
        </div>
      </div>

      {errors.submit && (
        <div className="text-red-600 text-sm">{errors.submit}</div>
      )}

      {/* Submit Button */}
      <div className="mt-8">
        <button
          type="submit"
          className="w-full flex justify-center py-4 px-4 rounded-xl text-lg font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          disabled={isLoading}
        >
          {isLoading ? <LoaderStats size="small" /> : 'Continue'}
        </button>
      </div>
    </form>
  );
};

export default BusinessInformationForm;
