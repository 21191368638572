import { CreditCardIcon } from '@heroicons/react/outline';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { WALLET } from '../../router/routeNames';
import {
  paymentMethodDeletebyId,
  selectPaymentMethodById,
} from './paymentMethodsSlice';

const PaymentMenthodDelete = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const paymentMethod = useSelector((state) =>
    selectPaymentMethodById(state, params.paymentMethodId)
  );
  const wallet = useSelector((state) => state.wallet);

  const handlePaymentMethodDelete = async () => {
    const paymentMethodData = {
      walletId: wallet.data?.uid,
      uid: params.paymentMethodId,
    };
    dispatch(paymentMethodDeletebyId(paymentMethodData));
  };

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="border-t border-gray-200 mb-10">
        <dl>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              Card Holde Name
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {paymentMethod?.name}
            </dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              Card Expiration Date
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{`${paymentMethod?.expMonth} / ${paymentMethod?.expYear}`}</dd>
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              Zip / Code Postal
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {paymentMethod?.postalCode}
            </dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Brand</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{`${paymentMethod?.brand} .... ${paymentMethod?.last4}`}</dd>
          </div>
        </dl>
      </div>
      <div className="mt-10 flex flex-row justify-end mb-10 mr-10">
        <Link
          to={WALLET}
          className="text-center w-44 px-6 py-3 border-2 border-gray-50 shadow-sm text-base font-medium rounded-md text-blue-1000
                                 bg-white hover:bg-gray-25 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-4"
        >
          Cancel
        </Link>
        <button
          onClick={handlePaymentMethodDelete}
          type="button"
          className="inline-flex self-end w-44 px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white
                                 bg-blue-1000 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Yes! Delete
          <CreditCardIcon className="ml-3 -mr-1 h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};

export default PaymentMenthodDelete;
