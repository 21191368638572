import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { responseMessage, StripeClient } from '../../api/stripe/client';
import { PaymentIntent } from '../../api/stripe/model';
import { isEmpty } from '../../utils/stringHelper';
import PaymentMethodDebitCard from '../paymentMethods/PaymentMethodDebitCard';
import { selectPaymentMethodByPaymentMethodId } from '../paymentMethods/paymentMethodsSlice';
import WalletState from './WalletState';
import WalletProcessing from './WalletProcessing';
import {
  walletMessageAdd,
  walletsStatus,
  walletTitleAdd,
  walletUpdate,
} from './walletSlice';
import {
  WALLETS_FUND_ADD_FAILED,
  WALLETS_FUND_ADD_PENDING,
  WALLETS_FUND_ADD_SUCCESS,
  WALLETS_READY,
  WALLET_ADD_SUCCEEDED,
} from './walletStatus';
import { useStripe } from '@stripe/react-stripe-js';
import { DASHBOARD } from '../../router/routeNames';
import { get } from 'lodash';

const WalletAddFund = () => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const [amount, setAmount] = useState(100);
  const [errorMessage, setErrorMessage] = useState('');
  const wallet = useSelector((state) => state.wallet);
  const user = useSelector((state) => state.user);
  const paymentMethod = useSelector((state) =>
    selectPaymentMethodByPaymentMethodId(state, wallet?.data?.paymentMethodId)
  );

  const handleAmount = (data) => {
    setAmount(data.target.value);
  };

  const handleTransferFund = async () => {
    try {
      if (Number(amount) < 20) {
        setErrorMessage('Add Balance Minimum of 20.');
        return;
      }
      dispatch(walletsStatus(WALLETS_FUND_ADD_PENDING));
      const userData = get(user, 'data');
      const walletData = get(wallet, 'data');
      const paymentIntentData = new PaymentIntent(userData, walletData, amount);
      const stripeClient = new StripeClient(
        process.env.REACT_APP_PAYMENT_INTENTS_URL
      );
      const holdFunds = await stripeClient.Post(
        '/create-payment-intent',
        JSON.stringify(paymentIntentData)
      );
      const statusCode = get(holdFunds, 'data.statusCode');
      const paymentIntentMessage = get(holdFunds, 'data.message');
      const paymentIntentError = get(holdFunds, 'error');
      const paymentIntentCode = get(holdFunds, 'data.code');
      const paymentIntentDeclineCode = get(holdFunds, 'data.decline_code');
      if (!isEmpty(statusCode) && statusCode !== 200) {
        const logData = {};
        logData.userId = userData?.uid;
        logData.contact = userData?.email || userData?.phoneNumber;
        logData.message = paymentIntentMessage || paymentIntentError;
        logData.type = WALLETS_FUND_ADD_FAILED;
        const message = responseMessage(
          paymentIntentCode,
          paymentIntentDeclineCode
        );
        dispatch(walletMessageAdd(message || 'Failed add Fund to Wallet'));
        dispatch(walletTitleAdd('Add Fund - Failed'));
        dispatch(walletsStatus(WALLETS_FUND_ADD_FAILED));
        return;
      }
      const clientSecret = get(holdFunds, 'data.clientSecret');
      const holdFundsId = (holdFunds, 'data.id');
      if (isEmpty(clientSecret) || isEmpty(holdFundsId)) {
        dispatch(walletMessageAdd('Failed - Add Fund to Wallet'));
        dispatch(walletTitleAdd('Add Fund - Failed'));
        dispatch(walletsStatus(WALLETS_FUND_ADD_FAILED));
        return;
      }
      const returnUrl = `${window.location.origin}${DASHBOARD}`;
      const paymentMethod = get(paymentIntentData, 'payment_method');
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethod,
        return_url: returnUrl,
      });
      const status = get(result, 'paymentIntent.status');
      const confirmStatusCode = get(result, 'error.code');
      const confirmDeclineCode = get(result, 'error.decline_code');
      const error = get(result, 'error');
      if (
        (confirmStatusCode && confirmStatusCode !== 200) ||
        status !== WALLET_ADD_SUCCEEDED ||
        error
      ) {
        const message = responseMessage(confirmStatusCode, confirmDeclineCode);
        dispatch(walletTitleAdd('Recharge Failed'));
        dispatch(walletMessageAdd(message));
        dispatch(walletsStatus(WALLETS_FUND_ADD_FAILED));
        return;
      }

      const totalAmount = Number(walletData.amount) + Number(amount);
      const walletResponse = {
        ...walletData,
        amount: totalAmount,
      };
      dispatch(walletUpdate(walletResponse));
      dispatch(walletsStatus(WALLETS_FUND_ADD_SUCCESS));
    } catch (error) {
      dispatch(walletsStatus(WALLETS_FUND_ADD_FAILED));
    }
  };

  // Wallet Processing - No Wallet Read, No Payment Method
  if (wallet.status !== WALLETS_READY || paymentMethod === undefined) {
    return <WalletProcessing />;
  }

  if (
    wallet.status === WALLETS_READY &&
    isEmpty(wallet?.data?.paymentMethodId)
  ) {
    return (
      <WalletState
        title="Add Payment Method"
        description="First time here! Setup Payment Method to sent credit to your loves ones."
      />
    );
  }

  const renderAddFundForm = () => {
    return (
      <div className="relative z-0 flex overflow-hidden bg-white rounded-3xl flex-1 justify-center">
        <div>
          <label
            htmlFor="price"
            className="block text-lg mb-2 font-medium text-gray-700"
          >
            Transfer Fund to your Wallet
          </label>
          <p className="text-red-600 text-xs font-bold">{errorMessage}</p>
          <div className="mt-1 relative rounded-md shadow-sm mb-4">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span className="text-gray-500 sm:text-sm">$</span>
            </div>
            <input
              value={amount}
              type="text"
              name="price"
              id="price"
              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-base border-gray-300 rounded-md p-4"
              placeholder="0.00"
              aria-describedby="price-currency"
              onChange={handleAmount}
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <span className="text-gray-500 sm:text-sm" id="price-currency">
                USD
              </span>
            </div>
          </div>
          <PaymentMethodDebitCard id={paymentMethod?.uid} />
          <button
            type="button"
            onClick={handleTransferFund}
            className="w-full text-center inline-flex items-center px-2.5 py-1.5 border border-transparent 
                                text-lg font-medium rounded shadow-sm mt-5
                            text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Add Balance to Wallet
          </button>
        </div>
      </div>
    );
  };

  return renderAddFundForm();
};

export default WalletAddFund;
