import React from 'react';
import Svg from '../../assets/svg';
import { LOGIN, PERSONAL_INFORMATION } from '../../router/routeNames';
import CopyrightText from '../../components/atoms/CopyrightText';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';

const AuthEmailVerified = () => {
  const navigate = useNavigate();

  const handleLetsDoIt = async () => {
    const auth = getAuth().currentUser;
    if (!auth) {
      navigate(LOGIN, { replace: true });
      return;
    }
    navigate(PERSONAL_INFORMATION, { replace: true });
  };

  return (
    <div className="flex flex-col items-center bg-white">
      <div className="mx-auto w-full max-w-sm lg:max-w-lg px-4 lg:px-0 mt-[150px]">
        <div className="flex flex-col items-center">
          <div className="mb-[30px] flex justify-center">
            <img
              src={Svg.email_verified}
              alt="Email Icon"
              className="object-contain h-20 w-20 lg:h-24 lg:w-24"
            />
          </div>
          <h1 className="text-center text-gray-900 text-2xl lg:text-3xl font-bold leading-[1.2]">
            Thank you for joining us as a partner
          </h1>
          <p className="text-center text-gray-500 text-lg font-medium leading-normal tracking-tight">
            We are very happy to have you as JLRSpace Partner. We need some more
            information from you to complete your profile.{' '}
          </p>
          <button
            type="button"
            onClick={handleLetsDoIt}
            className="w-full max-w-[436px] mb-[10px] h-[60px] p-2.5 bg-indigo-700 rounded-xl flex justify-center items-center gap-2.5 mt-[36px] hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <h3 className="text-center text-white text-lg font-bold leading-normal tracking-tight">
              Let’s do it
            </h3>
          </button>
        </div>
      </div>
      <div className="absolute bottom-[5px] w-full">
        <CopyrightText />
      </div>
    </div>
  );
};

export default AuthEmailVerified;
