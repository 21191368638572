import React, { useState } from 'react';
import {
  useStripe,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useElements
} from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import {
  paymentMethodsAdd,
  paymentMethodStatus
} from '../paymentMethods/paymentMethodsSlice';
import {
  PAYMENTMETHODS_ADD_FAILED,
  PAYMENTMETHODS_ADD_PENDING,
  PAYMENTMETHODS_READY
} from '../paymentMethods/paymentMethodState';
import { get } from 'lodash';
import {
  businessUpdate,
  selectBusinessSelected
} from '../business/businessSlice';
import { walletPaymentMethodAdd, walletsStatus } from './walletSlice';
import {
  WALLETS_ADD_PAYMENTMETHOD_FAILED,
  WALLETS_READY
} from './walletStatus';
import Log from '../../models/log';
import FirestoreLogs from '../../api/firebase/firestore.logs';
import { userUpdate } from '../user/userSlice';
import { WALLET_SETUP_SUCCESSFULLY } from '../../router/routeNames';
import { useNavigate } from 'react-router-dom';
import { walletData } from '../../constant/walletData';
import { isPostalCode } from 'validator';
import { countryCodeList } from '../../constant/countryCodeList';

const WalletPaymentMethodForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const user = useSelector((state) => state.user);
  const userData = get(user, 'data', {});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    cardHolderName: '',
    cardNumber: '',
    cardExpiry: '',
    cardCvc: '',
    zipCode: '',
    street: ''
  });
  const [errors, setErrors] = useState({});
  const business = useSelector((state) => selectBusinessSelected(state));
  const paymentMethods = useSelector((state) => state.paymentMethods);
  const isShowZipCodeInput = countryCodeList.includes(userData?.countryCode);

  const handleInputChange = (e) => {
    if (e.error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [e.elementType]: e.error.message
      }));
    } else if (e.elementType) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [e.elementType]: ''
      }));
    }

    if (e.target) {
      const { name, value } = e.target;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value
      }));

      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: ''
      }));
    }
  };

  const validateForm = () => {
    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);

    const validationErrors = {};

    if (!cardNumberElement || cardNumberElement._empty) {
      validationErrors.cardNumber = 'Card Number is required';
    }
    if (!cardExpiryElement || cardExpiryElement._empty) {
      validationErrors.cardExpiry = 'Expiry Date is required';
    }
    if (!cardCvcElement || cardCvcElement._empty) {
      validationErrors.cardCvc = 'CVC is required';
    }
    if (!formData.cardHolderName.trim()) {
      validationErrors.cardHolderName = 'Card Holder Name is required';
    }
    if (!formData.zipCode.trim() && isShowZipCodeInput) {
      validationErrors.zipCode = 'Zip Code is required';
    }

    if (isShowZipCodeInput) {
      if (!isPostalCode(formData.zipCode, userData?.countryCode)) {
        validationErrors.zipCode = 'Invalid postal code format.';
      }
    }

    if (!formData.street.trim()) {
      validationErrors.street = 'Street is required';
    }

    return validationErrors;
  };

  const handleSubmit = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      dispatch(paymentMethodStatus(PAYMENTMETHODS_ADD_PENDING));
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: formData.cardHolderName,
          address: {
            postal_code: formData.zipCode || '',
            country: userData?.countryCode,
            line1: formData.street,
            state: userData?.state,
            city: userData?.city
          },
          phone: userData.phone || null,
          email: userData?.email || null
        }
      });

      if (error) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          errorMessage: error.message
        }));
        dispatch(paymentMethodStatus(PAYMENTMETHODS_ADD_FAILED));
        return;
      }
      if (paymentMethod) {
        const newDebitCard = {
          walletId: userData.uid,
          name: formData.cardHolderName,
          brand: paymentMethod.card.brand,
          country: paymentMethod.card.country,
          customerId: user.data.stripeId,
          expMonth: paymentMethod.card.exp_month,
          expYear: paymentMethod.card.exp_year,
          funding: paymentMethod.card.funding,
          last4: paymentMethod.card.last4,
          paymentMethodId: paymentMethod.id,
          stripeId: userData.stripeId,
          userId: userData.uid,
          isDefault: false,
          type: 'card',
          amount: 0,
          businessId: business?.uid,
          fullName: formData.cardHolderName,
          postalCode: formData.zipCode,
          addressLine1: formData.street
        };
        let walletInfo = {...walletData};
        walletInfo.paymentMethodId = paymentMethod.id;
        dispatch(
          walletPaymentMethodAdd({
            uid: userData.uid,
            userId: userData.uid,
            businessId: business?.uid,
            ...walletInfo
          })
        );
        dispatch(paymentMethodsAdd(newDebitCard));
        dispatch(walletsStatus(WALLETS_READY));
        dispatch(paymentMethodStatus(PAYMENTMETHODS_READY));

        // Ensure business has wallet
        if (!business?.walletId || !business?.isApproved) {
          const businessData = {
            uid: business.uid,
            walletId: userData?.uid,
            isApproved: true
          };
          dispatch(businessUpdate(businessData));
        }

        if (!userData.isWalletSetup) {
          dispatch(userUpdate({ uid: userData.uid, isWalletSetup: true }));
        }
        navigate(WALLET_SETUP_SUCCESSFULLY, { replace: true });
      }
    } catch (error) {
      const log = new Log({
        userId: userData.uid,
        contact: userData.email || userData.phoneNumber,
        message: 'Failed to add new Wallet',
        action: 'Component.AddDebitCard',
        exception: error.message || error,
        type: WALLETS_ADD_PAYMENTMETHOD_FAILED
      });
      setErrors((prevErrors) => ({
        ...prevErrors,
        errorMessage: error.message
      }));
      dispatch(paymentMethodStatus(PAYMENTMETHODS_ADD_FAILED));
      await FirestoreLogs.Add(log);
    }
  };

  return (
    <form className="max-w-lg mx-auto">
      <div className="w-full mb-4">
        <label className="text-gray-700 font-medium leading-normal tracking-tight">
          Card Number*
        </label>
        <div className="w-full mt-[6px]">
          <CardNumberElement
            onChange={handleInputChange}
            options={{
              style: {
                base: {
                  fontSize: '16px',
                  fontWeight: 500,
                  color: '#32325d',
                  '::placeholder': { color: '#aab7c4' }
                }
              }
            }}
            className={`w-full px-3 py-4 border rounded-md shadow-sm bg-gray-100 focus:ring-indigo-500 focus:border-indigo-500 focus:outline-none ${
              errors.cardNumber ? 'border-red-500' : 'border-gray-300'
            }`}
          />
          {errors.cardNumber && (
            <p className="text-red-500 text-xs mt-1">{errors.cardNumber}</p>
          )}
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full mb-4">
        <div>
          <label className="text-gray-700 font-medium">Expiry Date*</label>
          <CardExpiryElement
            onChange={handleInputChange}
            options={{
              style: {
                base: {
                  fontSize: '16px',
                  fontWeight: 500,
                  color: '#32325d',
                  '::placeholder': { color: '#aab7c4' }
                }
              }
            }}
            className={`w-full mt-[6px] px-3 py-4 border rounded-md shadow-sm bg-gray-100 focus:ring-indigo-500 focus:border-indigo-500 focus:outline-none ${
              errors.cardExpiry ? 'border-red-500' : 'border-gray-300'
            }`}
          />
          {errors.cardExpiry && (
            <p className="text-red-500 text-xs mt-1">{errors.cardExpiry}</p>
          )}
        </div>
        <div>
          <label className="text-gray-700 font-medium">CVC*</label>
          <CardCvcElement
            onChange={handleInputChange}
            options={{
              style: {
                base: {
                  fontSize: '16px',
                  fontWeight: 500,
                  color: '#32325d',
                  '::placeholder': { color: '#aab7c4' }
                }
              }
            }}
            className={`w-full mt-[6px] px-3 py-4 border rounded-md shadow-sm bg-gray-100 focus:ring-indigo-500 focus:border-indigo-500 focus:outline-none ${
              errors.cardCvc ? 'border-red-500' : 'border-gray-300'
            }`}
          />
          {errors.cardCvc && (
            <p className="text-red-500 text-xs mt-1">{errors.cardCvc}</p>
          )}
        </div>
      </div>

      <div className="w-full mb-4">
        <label className="text-gray-700 font-medium">Card Holder Name*</label>
        <input
          type="text"
          name="cardHolderName"
          placeholder="Enter your name here"
          value={formData.cardHolderName}
          onChange={handleInputChange}
          className={`w-full mt-[6px] px-3 py-4 border rounded-md shadow-sm placeholder-gray-400 focus:ring-indigo-500 focus:border-indigo-500 focus:outline-none bg-gray-100 ${
            errors.cardHolderName ? 'border-red-500' : 'border-gray-300'
          }`}
        />
        {errors.cardHolderName && (
          <p className="text-red-500 text-xs mt-1">{errors.cardHolderName}</p>
        )}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full mb-[44px]">
        {isShowZipCodeInput ? (
          <div>
            <label className="text-gray-700 font-medium">Zip Code*</label>
            <input
              type="text"
              name="zipCode"
              placeholder="Zip Code"
              value={formData.zipCode}
              onChange={handleInputChange}
              className={`w-full mt-[6px] px-3 py-4 border rounded-md shadow-sm placeholder-gray-400 focus:ring-indigo-500 focus:border-indigo-500 focus:outline-none bg-gray-100 ${
                errors.zipCode ? 'border-red-500' : 'border-gray-300'
              }`}
            />
            {errors.zipCode && (
              <p className="text-red-500 text-xs mt-1">{errors.zipCode}</p>
            )}
          </div>
        ) : null}
        <div className={`${isShowZipCodeInput ? 'w-full' : 'col-span-2'}`}>
          <label className="text-gray-700 font-medium">Street*</label>
          <input
            type="text"
            name="street"
            placeholder="Street Address"
            value={formData.street}
            onChange={handleInputChange}
            className={`w-full mt-[6px] px-3 py-4 border rounded-md shadow-sm placeholder-gray-400 focus:ring-indigo-500 focus:border-indigo-500 focus:outline-none bg-gray-100 ${
              errors.street ? 'border-red-500' : 'border-gray-300'
            }`}
          />
          {errors.street && (
            <p className="text-red-500 text-xs mt-1">{errors.street}</p>
          )}
        </div>
      </div>
      {errors.errorMessage && (
        <p className="text-red-500 text-xs mt-1 mb-4">{errors.errorMessage}</p>
      )}

      <button
        type="button"
        onClick={handleSubmit}
        className="w-full flex justify-center py-4 px-4 rounded-lg text-lg font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        disabled={paymentMethods.status === PAYMENTMETHODS_ADD_PENDING}
      >
        {paymentMethods.status === PAYMENTMETHODS_ADD_PENDING ? (
          <div className="loader border-t-transparent border-white w-5 h-5 border-2 rounded-full animate-spin"></div>
        ) : (
          'Continue'
        )}
      </button>
    </form>
  );
};

export default WalletPaymentMethodForm;
