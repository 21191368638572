import React, { useEffect } from 'react';
import ProductCardItem from './ProductCardItem';
import { useDispatch, useSelector } from 'react-redux';
import { selectProductsByOperatorId } from './productsSlice';
import { get } from 'lodash';
import { rechargeProductIdAdd } from '../recharge/rechargeSlice';

const ProductCardCollection = () => {
  const dispatch = useDispatch();
  const recharge = useSelector((state) => state.recharge);
  const phone = get(recharge, 'phone', '');
  const operatorUid = get(recharge, 'operatorUid', '');
  const products = useSelector((state) =>
    selectProductsByOperatorId(state, operatorUid)
  );

  useEffect(() => {
    // Select the first product by default
    if (products && products.length > 0) {
      dispatch(rechargeProductIdAdd(products[0].uid));
    }
  }, [products]);

  if (!products || !products.length || !phone) {
    return null;
  }

  return (
    <div className="flex flex-col items-start w-full">
      <div className="text-gray-900 text-base font-bold leading-normal tracking-tight mt-4 mb-4 text-left">
        Select Product
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 justify-items-start">
        {products.map((product) => (
          <ProductCardItem key={product.uid} uid={product.uid} />
        ))}
      </div>
    </div>
  );
};

export default ProductCardCollection;
