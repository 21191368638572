import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  query,
  collection,
  where,
  getDocs,
} from 'firebase/firestore';

import firebaseInit from './firebase.index';

const app = firebaseInit();
const db = getFirestore(app);

export default class FirestoreUser {
  static async LoadById(userId) {
    try {
      const userDocRef = doc(db, 'users', userId);

      const userDocSnap = await getDoc(userDocRef);

      return userDocSnap.exists() ? userDocSnap.data() : null;
    } catch (error) {
      return null;
    }
  }

  static async LoadByBusinessId(businessId) {
    try {
      const userCollections = [];
      const queryUsers = query(
        collection(db, 'users'),
        where('businessId', '==', businessId)
      );

      const usersSnapShot = await getDocs(queryUsers);

      usersSnapShot.forEach((snapshot) => {
        userCollections.push(snapshot.data());
      });
      return userCollections;
    } catch (error) {
      return null;
    }
  }

  static async SetRole(authData) {
    try {
      const ref = doc(db, 'users', authData.uid);
      await setDoc(ref, authData, { merge: true });
      return FirestoreUser.LoadById(authData.uid);
    } catch (error) {
      return null;
    }
  }

  static async Update(authData) {
    try {
      const ref = doc(db, 'users', authData.uid);
      await setDoc(ref, authData, { merge: true });
      return FirestoreUser.LoadById(authData.uid);
    } catch (error) {
      return null;
    }
  }
}
