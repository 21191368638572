export const BUSINESS_UNKNOWN = 'business_unknow';
export const BUSINESS_READY = 'business_ready';

export const BUSINESS_ADD_FAILED = 'business_add_failed';
export const BUSINESS_ADD_PENDING = 'business_add_pending';
export const BUSINESS_ADD_SUCCESS = 'business_add_success';

export const BUSINESS_UPDATE_FAILED = 'business_update_failed';
export const BUSINESS_UPDATE_PENDING = 'business_update_pending';
export const BUSINESS_UPDATE_SUCCESS = 'business_update_success';

export const BUSINESS_ADD_AGENT_PENDING = 'business_add_agent_pending';
export const BUSINESS_ADD_AGENT_FAILED = 'business_add_agent_failed';
export const BUSINESS_ADD_AGENT_SUCCESS = 'business_add_agent_success';
