import React, { useState } from 'react';
import { isValidEmail } from '../../utils/regexHelper';
import FirebaseAuth from '../../api/firebase/firebase.auth';
import { useDispatch } from 'react-redux';
import { authenticationEmailAdd } from './authenticationSlice';
import { useNavigate } from 'react-router-dom';
import { FORGOT_PASSWORD_OPEN_EMAIL, LOGIN } from '../../router/routeNames';
import LoaderStats from '../../components/loader/LoaderStats';
import CopyrightText from '../../components/atoms/CopyrightText';

const SendEmailResetPassword = () => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sendEmailResetPassword = async () => {
    try {
      setErrorMessage('');
      if (!isValidEmail(email)) {
        setErrorMessage('Invalid Email Address');
        return;
      }
      dispatch(authenticationEmailAdd(email));
      setLoading(true);
      const isEmailSent = await FirebaseAuth.SendResetPasswordEmailLink(email);
      if (isEmailSent) {
        navigate(FORGOT_PASSWORD_OPEN_EMAIL);
      }
    } catch (error) {
      setErrorMessage(
        'Failed to send Email Reset Password. Contact Support@jlrecharge.com'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center content-center bg-white py-10 md:py-20">
      <div className="flex-1 px-4 lg:px-8">
        <div className="text-center">
          <div className="text-gray-900 text-3xl md:text-[44px] font-black leading-tight md:leading-[64px]">
            Forgot password?
          </div>
          <div className="text-gray-500 text-base md:text-lg font-medium leading-normal tracking-tight mt-2">
            Please add your verified email to get a reset link.
          </div>
        </div>

        <div className="mt-8 flex justify-center">
          <div className="w-full max-w-xs md:max-w-md">
            <div className="mt-6">
              <form action="/" method="POST">
                <div>
                  <div className="text-black text-base md:text-lg font-bold leading-normal tracking-tight">
                    Email*
                  </div>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      placeholder="Enter your Email Address"
                      required
                      value={email}
                      onChange={(data) => setEmail(data.target.value)}
                      className={`appearance-none block w-full px-3 py-3 md:py-4 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-[16px] bg-gray-100 ${
                        errorMessage ? 'border-red-500' : 'border-gray-200'
                      }`}
                    />
                  </div>
                  {errorMessage && (
                    <div className="mt-1">
                      <span className="text-xs md:text-sm text-red-600 font-semibold">
                        {errorMessage}
                      </span>
                    </div>
                  )}
                </div>

                <div className="mt-8">
                  <button
                    type="button"
                    onClick={sendEmailResetPassword}
                    className="w-full flex justify-center py-3 md:py-4 rounded-xl text-base md:text-lg font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    disabled={loading}
                  >
                    {loading ? <LoaderStats /> : <span>Send link</span>}
                  </button>
                </div>

                <div className="mt-6">
                  <div
                    onClick={() => navigate(LOGIN)}
                    className="w-full md:w-[436px] h-12 md:h-[60px] p-2.5 rounded-xl border border-gray-200 flex justify-center items-center cursor-pointer hover:bg-gray-100 hover:border-indigo-700"
                  >
                    <div className="text-center text-indigo-700 text-base md:text-lg font-bold tracking-tight hover:text-indigo-900">
                      Login
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-[5px] w-full">
        <CopyrightText />
      </div>
    </div>
  );
};

export default SendEmailResetPassword;
