import React from 'react';
import SignIn from '../../features/authentication/SignIn';
import OnBoardingLogoHeader from '../../components/header/OnBoardingLogoHeader';

const Login = () => {
  return (
    <div>
      <OnBoardingLogoHeader />
      <SignIn />
    </div>
  );
};

export default Login;
