import React from 'react';
import OnBoardingLogoHeader from '../../components/header/OnBoardingLogoHeader';
import UserPersonalDetail from '../../features/user/UserPersonalDetail';

const UserPersonalInformation = () => {
  return (
    <div>
      <OnBoardingLogoHeader />
        <div className="w-[70%] h-1.5 bg-indigo-700 max-w-[70%] sm:max-w-[70%] md:max-w-[70%]" /> 
      <UserPersonalDetail />
    </div>
  );
};

export default UserPersonalInformation;
