export default class Log {
  constructor(log) {
    this.message = log.message || '';
    this.code = log.code || 500;
    this.type = log.type || 'EXCEPTION';
    this.contact = log.contact || '';
    this.userId = log.userId || '';
    this.action = log.action || '';
    this.exception = log.exception || '';
    this.status = log.status || 'error';
  }
}
