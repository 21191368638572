import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { UserCircleIcon } from '@heroicons/react/solid';
import ComboBoxesBase from '../../components/atoms/ComboBoxesBase';
import { userUpdate } from './userSlice';
import { selectedCountryByIsoName } from '../countries/countriesSlice';

const UserAddress = () => {
  const dispatch = useDispatch();

  const countriesEntities = useSelector((state) => state.countries.entities);
  const countries = Object.values(countriesEntities);

  const user = useSelector((state) => state.user);
  const selectedCountry = useSelector((state) =>
    selectedCountryByIsoName(state, user?.data?.countryCode)
  );

  const [postalCode, setPostalCode] = useState(user?.data?.postalCode);
  const [street, setStreet] = useState(user?.data?.street);
  const [stateValue, setState] = useState(user?.data?.state);
  const [cityValue, setCity] = useState(user?.data?.city);
  const [country, setCountry] = useState(selectedCountry);

  const onHandleSelectCountry = (data) => {
    setCountry(data);
  };

  const handleUpdateUser = () => {
    const userData = {
      uid: user?.data.uid,
      postalCode,
      street,
      city: cityValue,
      state: stateValue,
      country: country?.name || selectedCountry.name,
      countryCode: country?.isoName || selectedCountry.isoName,
      currencyCode: country?.currencyCode || selectedCountry.currencyCode,
      isActive: true,
    };
    dispatch(userUpdate(userData));
  };

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-10">
      <div className="flex flex-grow justify-between">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Personal Address
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Your Personal Address.
          </p>
        </div>
        <div className="px-4 py-5 sm:px-6">
          <span
            className={`inline-flex items-center px-5 py-2 rounded text-xs font-bold uppercase text-gray-600 ${
              user?.data?.isActive ? '' : 'bg-yellow-200'
            }`}
          >
            {user?.data?.isActive
              ? ''
              : 'Provide your Address to activate your account.'}
          </span>
        </div>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Street Address
            </dt>
            <input
              onChange={(data) => setStreet(data.target.value)}
              value={street}
              placeholder="Ex: 982 Main Street"
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
              type="text"
            ></input>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">State</dt>
            <input
              onChange={(data) => setState(data.target.value)}
              value={stateValue}
              placeholder="Ex: MA"
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
              type="text"
            ></input>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">City</dt>
            <input
              onChange={(data) => setCity(data.target.value)}
              value={cityValue}
              placeholder="Ex: Boston"
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
              type="text"
            ></input>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Postal Code</dt>
            <input
              onChange={(data) => setPostalCode(data.target.value)}
              value={postalCode}
              placeholder="Ex: 02301"
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
              type="text"
            ></input>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Country</dt>

            {countries.length > 0 ? (
              <ComboBoxesBase
                selectOptions={countries}
                selectedOption={country || selectedCountry}
                title=""
                handleSelect={onHandleSelectCountry}
              />
            ) : (
              <></>
            )}
          </div>
        </dl>
      </div>
      <div className="px-4 py-5 sm:px-6 flex flex-row justify-end border-y-2 border-solid">
        <button
          type="button"
          onClick={handleUpdateUser}
          className="inline-flex  items-center px-5 py-3 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <UserCircleIcon className="mr-2 -ml-1 h-4 w-4" aria-hidden="true" />
          Update
        </button>
      </div>
    </div>
  );
};

export default UserAddress;
