import React from 'react';
import AuthEmail from '../../features/authentication/AuthEmail';
import OnBoardingLogoHeader from '../../components/header/OnBoardingLogoHeader';

const SignUp = () => {
  return (
    <div>
      <OnBoardingLogoHeader />
      <AuthEmail />
    </div>
  );
};
export default SignUp;
