import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  rechargeMessageAdd,
  rechargePaymentMethodIdAdd,
  rechargeStatus,
} from '../recharge/rechargeSlice';
import {
  RECHARGE_PROCESS_PAYMENT,
  RECHARGE_PROCESS_PAYMENT_FAILED,
  RECHARGE_PROCESS_PAYMENT_SUCCESSFULLY,
  RECHARGE_PROCESS_TOPOP_INSUFICIENT_CREDIT,
  RECHARGE_PROCESS_TOPUP,
  RECHARGE_PROCESS_TOPUP_FAILED,
  RECHARGE_PROCESS_TOPUP_SUCCESSFULLY,
} from '../recharge/rechargeStatus';
import { selectOperatorById } from '../operators/operatorsSlice';
import { codeGenerator } from '../../utils/codeGenerator';
import { ProviderClient } from '../../api/providers/client';
import { selectPaymentMethodByPaymentMethodId } from '../paymentMethods/paymentMethodsSlice';
import { isEmpty } from '../../utils/stringHelper';
import { walletAdd, walletUpdate } from './walletSlice';
import { transactionsAdd } from '../transactions/transactionsSlice';
import { concactCallingCodeAndPhone } from '../../utils/phoneHelper';
import { selectBusinessSelected } from '../business/businessSlice';
import NoPaymentMethod from '../paymentMethods/NoPaymentMethod';
import { logsAdd } from '../logs/logSlice';
import Log from '../../models/log';
import FirestoreWallets from '../../api/firebase/firestore.wallets';
import Loader from '../../components/loader/Loader';
import { ERROR } from '../../api/io/httpClient';
import { get } from 'lodash';
import { TRANSACTION_PROCESSING_COMPLETE } from '../transactions/transactionState';
import { selectProductById } from '../products/productsSlice';

const PaymentMethods = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const recharge = useSelector((state) => state.recharge);
  const operator = useSelector((state) =>
    selectOperatorById(state, recharge.operatorId)
  );
  const wallet = useSelector((state) => state.wallet);
  const walletData = get(wallet, 'data', {});
  const paymentMethod = useSelector((state) =>
    selectPaymentMethodByPaymentMethodId(state, walletData?.paymentMethodId)
  );
  const businessSelected = useSelector((state) =>
    selectBusinessSelected(state)
  );

  const product = useSelector((state) =>
    selectProductById(state, recharge.productId)
  );

  const [inputErrorMessage, setInputErrorMessage] = useState('');
  useEffect(() => {
    if (wallet !== undefined) {
      dispatch(rechargePaymentMethodIdAdd(walletData?.paymentMethodId));
    }
  }, []);

  const handlePaymentClick = async () => {
    try {
      const userData = get(user, 'data', {});
      if (!recharge.phone) {
        setInputErrorMessage('Please enter the valid phone number.');
        return;
      }
      if (!recharge.amountReceived || Number(recharge.amountReceived) == 0) {
        setInputErrorMessage(
          'The amount must be greater than 0. Please enter a valid amount.'
        );
        return;
      }
      setInputErrorMessage('');
      setIsLoading(true);
      const phoneNumber = concactCallingCodeAndPhone(
        recharge.countryCallingCode,
        recharge.phone
      );
      //TODO: Check if Wallet has enough Money
      if (Number(walletData?.amount) < Number(recharge.totalAmount)) {
        dispatch(rechargeStatus(RECHARGE_PROCESS_TOPOP_INSUFICIENT_CREDIT));
        return;
      }
      let walletAmount =
        Number(walletData?.amount) -
        Number(recharge.totalAmount) +
        Number(recharge.userCommission);
      walletAmount = walletAmount.toFixed(2);
      const walletInfo = {
        ...wallet.data,
        amount: Number(walletAmount),
      };

      dispatch(rechargeStatus(RECHARGE_PROCESS_PAYMENT));
      const updateWallet = await FirestoreWallets.Update(walletInfo);
      if (walletUpdate == null) {
        dispatch(rechargeStatus(RECHARGE_PROCESS_PAYMENT_FAILED));
        return;
      }
      dispatch(walletAdd(updateWallet));
      dispatch(rechargeStatus(RECHARGE_PROCESS_PAYMENT_SUCCESSFULLY));
      const topUpReference = codeGenerator();
      const providerClient = new ProviderClient(process.env.REACT_APP_API_HOST);
      let topUpData = {
        reference: topUpReference,
        operatorId: recharge.operatorId,
        amount: Number(recharge.amountSent),
        amountReceived: operator.supportLocalAmount
          ? Number(recharge.amountReceived)
          : Number(recharge.amountSent),
        useLocalAmount: operator.supportLocalAmount,
        ValidateOnly: false,
        customerIdentifier: topUpReference,
        skuCode: operator.skuCode,
        currency: userData.currencyCode?.toUpperCase(),
        recipientPhone: {
          countryCode: recharge.receiverCountryCode,
          number: phoneNumber,
        },
        senderPhone: {
          countryCode: userData.countryCode,
          number: userData.phoneNumber || '1401-216-7175',
        },
        date: new Date().toISOString(),
        productType: get(product, 'productType', 'recharge'),
      };

      dispatch(rechargeStatus(RECHARGE_PROCESS_TOPUP));
      topUpData.providerId = operator.providerId;
      topUpData.commission = operator.commissionGain;
      let topUpResult = await providerClient.Reacharge(
        `/${operator.endPoint}`,
        JSON.stringify(topUpData)
      );
      if (topUpResult.status === ERROR || topUpResult.data?.status === ERROR) {
        // Tracked Failed
        let logData = {};
        logData.userId = userData?.uid;
        logData.contact = userData?.email || userData?.phoneNumber;
        logData.phone = phoneNumber;
        logData.providerId = topUpData.providerId;
        logData.providerName = operator.endPoint;
        logData.amount = topUpData.amount;
        logData.message = topUpResult?.data?.error || '';
        logData.type = RECHARGE_PROCESS_TOPUP_FAILED;

        dispatch(rechargeStatus(RECHARGE_PROCESS_TOPUP_FAILED));
        dispatch(rechargeMessageAdd(topUpResult?.data || 'Failed Second Try'));
        dispatch(logsAdd(new Log(logData)));

        walletAmount =
          Number(walletAmount) +
          Number(recharge.totalAmount) -
          Number(recharge.userCommission);
        walletAmount = walletAmount.toFixed(2);
        const walletData = {
          ...wallet.data,
          amount: Number(walletAmount),
        };
        const updateWallet = await FirestoreWallets.Update(walletData);
        if (walletUpdate == null) {
          return;
        }
        dispatch(walletAdd(updateWallet));
        return;
      }
      const rechargeFeesResponse = get(recharge, 'feesResponse', {});
      if (isEmpty(rechargeFeesResponse) || isEmpty(recharge)) {
        return;
      }
      const transaction = {
        ...rechargeFeesResponse,
        reference: topUpReference,
        operatorId: recharge.operatorId,
        operatorFlag: operator.logo,
        amountReceived: Number(recharge.amountReceived),
        amountSent: Number(recharge.amountSent),
        fees: Number(recharge.fees),
        totalAmount: Number(recharge.totalAmount),
        phone: phoneNumber,
        userId: userData?.uid,
        senderCountryCode: userData?.countryCode,
        receiverCountryCode: recharge.receiverCountryCode,
        operatorName: recharge.operatorName,
        paymentMethodId: recharge.paymentMethodId,
        senderCurrencyCode: userData?.currencyCode,
        productId: recharge.productId,
        receiverCurrencyCode: recharge.receiverCurrencyCode,
        commission: topUpData.commission,
        providerId: topUpData.providerId,
        device: 'jlrspace',
        status: TRANSACTION_PROCESSING_COMPLETE,
        businessId: businessSelected.uid,
        userCommission: Number(recharge.userCommission),
      };
      dispatch(transactionsAdd(transaction));
      dispatch(rechargeStatus(RECHARGE_PROCESS_TOPUP_SUCCESSFULLY));
    } catch (error) {
      dispatch(rechargeStatus(RECHARGE_PROCESS_TOPUP_FAILED));
      dispatch(rechargeMessageAdd(error.message));
      const logData = {};
      logData.userId = user?.data?.uid;
      logData.phone = recharge.phone;
      // TODO: Find the provider Name used
      logData.providerName = 'FindOut';
      logData.message = error.message || error;
      logData.type = 'EXCEPTION';
      dispatch(logsAdd(new Log(logData)));
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (Number(recharge.amountReceived > 0) || recharge.phone) {
      setInputErrorMessage('');
    }
  }, [recharge.amountReceived, recharge.phone]);
  const renderPaymentMethods = () => {
    return (
      <div className="px-8 pb-5">
        {isLoading || recharge?.feeLoading ? (
          <Loader />
        ) : (
          <button
            onClick={handlePaymentClick}
            type="button"
            disabled={!isEmpty(walletData?.paymentMethodId) ? false : true}
            className={`rounded-md w-full justify-center inline-flex items-center px-6 mt-2 py-3 border border-transparent 
							text-base font-medium shadow-sm ${
                !isEmpty(walletData?.paymentMethodId)
                  ? 'text-white bg-indigo-600'
                  : 'bg-gray-400 text-gray-900'
              } hover:bg-indigo-700 focus:outline-none 
							focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
          >
            Send -{' '}
            {`$${recharge.amountReceived || '0.00'} ${
              recharge.receiverCurrencyCode || ''
            }`}
          </button>
        )}
        {inputErrorMessage ? (
          <p className="text-xs font-bold text-red-600 mt-2">
            {inputErrorMessage}
          </p>
        ) : null}
      </div>
    );
  };

  return paymentMethod !== undefined ? (
    renderPaymentMethods()
  ) : (
    <NoPaymentMethod
      title="Payment Methods"
      description="Add Payment Method to Recharge your Wallet."
    />
  );
};

export default PaymentMethods;
