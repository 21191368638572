import React from 'react';
import OnBoardingLogoHeader from '../../components/header/OnBoardingLogoHeader';
import { useNavigate } from 'react-router-dom';
import {
  BUSINESS_INFORMATION,
  SPLASH,
  WALLET_SETUP_PAYMENT_METHOD
} from '../../router/routeNames';
import Svg from '../../assets/svg';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { userUpdate } from '../../features/user/userSlice';
const WalletSetup = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const userData = get(user, 'data', {});
  const dispatch = useDispatch();

  const handleBackClick = () => {
    navigate(BUSINESS_INFORMATION, { replace: true });
  };

  const handleSkipNow = () => {
    let userInfo = {
      uid: userData?.uid,
      isWalletSetup: true,
    };
    dispatch(userUpdate(userInfo));
    navigate(SPLASH, { replace: true });
  };
  const handleLetsAddNow = () => {
    navigate(WALLET_SETUP_PAYMENT_METHOD, { replace: true });
  };
  return (
    <div className="flex-1 min-h-screen flex flex-col bg-white">
      <OnBoardingLogoHeader />
      <div
        className="w-full h-1.5 bg-indigo-700 mb-6"
        style={{ width: '80%' }}
      />
      <div className="lg:grid-cols-2 gap-y-8 lg:gap-x-20 flex-1 px-6 lg:px-16">
        <div className="flex flex-col h-full">
          <div
            className="w-[80px] md:w-[100px] h-10 md:h-11 px-2 py-1 md:px-2.5 md:py-1.5 rounded-lg border border-gray-200 flex justify-center items-center gap-2 cursor-pointer mb-4"
            onClick={handleBackClick}
          >
            <div className="flex items-center gap-2">
              <img
                src={Svg.back}
                alt="Back Icon"
                className="object-contain h-5 w-5 md:h-6 md:w-6"
              />
              <span className="text-indigo-500 text-sm font-bold">Back</span>
            </div>
          </div>

          <div className="w-full flex justify-start">
            <div className="w-full">
              <div className="mx-auto w-full max-w-sm lg:max-w-lg px-4 lg:px-0 ">
                <div className="flex flex-col items-center mt-[20px]">
                  <div className="mb-[22px] flex justify-center">
                    <img
                      src={Svg.wallet}
                      alt="Wallet"
                      className="object-contain"
                    />
                  </div>
                  <h1 className="text-center text-gray-900 text-2xl lg:text-3xl font-bold leading-[1.2]">
                    Setup a Wallet
                  </h1>
                  <div className="text-center text-gray-500 text-lg font-medium leading-normal tracking-tight">
                    We recommend you to setup your wallet. We never charge from
                    your card until you make any recharge from account.
                  </div>
                  <button
                    onClick={handleLetsAddNow}
                    className="w-full max-w-[436px] h-[60px] p-2.5 bg-indigo-700 rounded-xl flex justify-center items-center gap-2.5 mt-[36px] hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <div className="text-center text-white text-lg font-bold leading-normal tracking-tight">
                      Let’s add now
                    </div>
                  </button>
                  <button
                    onClick={handleSkipNow}
                    className="w-[436px] text-center mt-[33px] text-gray-400 text-lg font-bold leading-normal tracking-tight"
                  >
                    Skip for now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-24 flex items-end justify-center mt-6 mb-4 text-center">
        <div className="text-gray-500 text-xs font-medium">
          © 2024 JLRecharge, LLC. All rights reserved.
        </div>
      </div>
    </div>
  );
};

export default WalletSetup;
