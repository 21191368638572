import React from 'react';
import AuthEmailVerification from '../../features/authentication/AuthEmailVerification';
import OnBoardingLogoHeader from '../../components/header/OnBoardingLogoHeader';

const EmailVerification = () => (
  <div>
    <OnBoardingLogoHeader />
      <div className="w-[25%] h-1.5 bg-indigo-700 max-w-[50%] sm:max-w-[50%] md:max-w-[50%]"></div>
    <AuthEmailVerification />
  </div>
);

export default EmailVerification;
