import {
  CurrencyDollarIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
  HashtagIcon,
  CashIcon,
} from '@heroicons/react/outline';

export const TransactionStatsType = {
  TOTAL_RECHARGE_SENT: 'total_recharge_sent',
  TOTAL_RECHARGE_SOLD: 'total_recharge_sold',
  TOTAL_RECHARGE_COST: 'total_recharge_cost',
  TOTAL_TRANSACTION_SUCCESSEFUL: 'total_recharge_successful',
  TOTAL_TRANSACTION_FAILED: 'total_recharge_failed',
  TOTAL_WALLET_BALANCE: 'total_wallet_balance',
  TOTAL_COMMISION: 'total_commision',
};

export const StatsTypeIcons = {
  total_recharge_sent: HashtagIcon,
  total_recharge_sold: CurrencyDollarIcon,
  total_recharge_cost: CurrencyDollarIcon,
  total_recharge_successful: CheckCircleIcon,
  total_recharge_failed: ExclamationCircleIcon,
  total_wallet_balance: CashIcon,
  total_commision: CurrencyDollarIcon,
};
