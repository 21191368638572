import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import FirestorePaymentMethod from '../../api/firebase/firestore.paymentMethods';
import {
  PAYMENTMETHODS_DELETE_DONE,
  PAYMENTMETHODS_DELETE_FAILED,
  PAYMENTMETHODS_DELETE_PENDING,
  PAYMENTMETHODS_LOADED_DONE,
  PAYMENTMETHODS_LOADED_FAILED,
  PAYMENTMETHODS_LOADED_PENDING,
  UNKNOWN,
} from './paymentMethodState';

const paymentMethodsAdapter = createEntityAdapter({
  selectId: (paymentMethod) => paymentMethod.uid,
});
const initialState = paymentMethodsAdapter.getInitialState({
  status: UNKNOWN,
});

export const loadPaymentMethods = createAsyncThunk(
  'paymentMethods/load',
  async (walletId) => {
    const paymentMethods = await FirestorePaymentMethod.LoadByWalletId(
      walletId
    );
    return paymentMethods;
  }
);

export const paymentMethodMakeDefault = createAsyncThunk(
  'paymentMethod/makeDefault',
  async (pmData) => {
    const paymentMethod = await FirestorePaymentMethod.MakeDefault(pmData);
    return paymentMethod;
  }
);

export const paymentMethodsAdd = createAsyncThunk(
  'paymentMethod/Add',
  async (pmData) => {
    const paymentMethod = await FirestorePaymentMethod.Add(pmData);
    return paymentMethod;
  }
);

export const paymentMethodDeletebyId = createAsyncThunk(
  'paymentMethod/DeleteById',
  async (paymentMethod) => {
    const pmid = await FirestorePaymentMethod.DeleteById(paymentMethod);
    return pmid;
  }
);

const paymentMethodsSlice = createSlice({
  name: 'paymentMethodsSlice',
  initialState,
  reducers: {
    paymentMethodStatus(state, action) {
      state.status = action.payload;
    },
    paymentMethodsAddMany: paymentMethodsAdapter.addMany,
    paymentMethodsAddOne: paymentMethodsAdapter.addOne,
    paymentMethodsRemoveOne: paymentMethodsAdapter.removeOne,
    paymentMethodsUpdateOne: paymentMethodsAdapter.updateOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadPaymentMethods.pending, (state) => {
        state.status = PAYMENTMETHODS_LOADED_PENDING;
      })
      .addCase(loadPaymentMethods.rejected, (state) => {
        state.status = PAYMENTMETHODS_LOADED_FAILED;
      })
      .addCase(loadPaymentMethods.fulfilled, (state, action) => {
        state.status =
          action.payload !== null
            ? PAYMENTMETHODS_LOADED_DONE
            : PAYMENTMETHODS_LOADED_FAILED;
        if (action.payload !== null) {
          paymentMethodsAdapter.setAll(action.payload);
        }
      })
      .addCase(paymentMethodMakeDefault.pending, (state) => {
        state.status = PAYMENTMETHODS_LOADED_PENDING;
      })
      .addCase(paymentMethodMakeDefault.rejected, (state) => {
        state.status = PAYMENTMETHODS_LOADED_FAILED;
      })
      .addCase(paymentMethodMakeDefault.fulfilled, (state, action) => {
        state.status =
          action.payload !== null
            ? PAYMENTMETHODS_LOADED_DONE
            : PAYMENTMETHODS_LOADED_FAILED;
        if (action.payload !== null) {
          paymentMethodsAdapter.addOne(state, action.payload);
        }
      })
      .addCase(paymentMethodsAdd.pending, (state) => {
        state.status = PAYMENTMETHODS_LOADED_PENDING;
      })
      .addCase(paymentMethodsAdd.rejected, (state) => {
        state.status = PAYMENTMETHODS_LOADED_FAILED;
      })
      .addCase(paymentMethodsAdd.fulfilled, (state, action) => {
        state.status =
          action.payload !== null
            ? PAYMENTMETHODS_LOADED_DONE
            : PAYMENTMETHODS_LOADED_FAILED;
        if (action.payload !== null) {
          paymentMethodsAdapter.addOne(state, action.payload);
        }
      })
      .addCase(paymentMethodDeletebyId.pending, (state) => {
        state.status = PAYMENTMETHODS_DELETE_PENDING;
      })
      .addCase(paymentMethodDeletebyId.rejected, (state) => {
        state.status = PAYMENTMETHODS_DELETE_FAILED;
      })
      .addCase(paymentMethodDeletebyId.fulfilled, (state, action) => {
        state.status =
          action.payload !== null
            ? PAYMENTMETHODS_DELETE_DONE
            : PAYMENTMETHODS_DELETE_FAILED;
        if (action.payload !== null) {
          paymentMethodsAdapter.removeOne(state, action.payload);
        }
      });
  },
});

export const {
  paymentMethodsAddMany,
  paymentMethodsAddOne,
  paymentMethodsRemoveOne,
  paymentMethodsUpdateOne,
  paymentMethodStatus,
} = paymentMethodsSlice.actions;

export default paymentMethodsSlice.reducer;

export const {
  selectAll: selectPaymentMethods,
  selectById: selectPaymentMethodById,
} = paymentMethodsAdapter.getSelectors((state) => state.paymentMethods);

export const selectPaymentMethodsId = createSelector(
  selectPaymentMethods,
  (paymentMethods) => paymentMethods.map((paymentMethod) => paymentMethod.uid)
);

export const selectPaymentMethodByPaymentMethodId = createSelector(
  selectPaymentMethods,
  (_, paymentMethodId) => paymentMethodId,
  (paymentMethods, paymentMethodId) =>
    paymentMethods.filter(
      (paymentMethod) => paymentMethod.paymentMethodId === paymentMethodId
    )[0]
);
