export const PRIVACY_POLICY = 'https://www.jlrecharge.com/privacy-policy';
export const TERMS_OF_SERVICE = 'https://www.jlrecharge.com/terms-of-service';
export const VALIDATION_ERROR = 'validation_error';
export const PRODUCT_TYPES = {
  RECHARGE: 'recharge',
  BUNDLE: 'bundles'
};
export const APPLICATION_ID = {
  JLRECHARGE: 'jlrecharge',
  JLRSPACE: 'jlrspace'
};
