export const RECHARGE_UNKNOWN = 'recharge_unknown';
export const RECHARGE_ADD_PHONE_NUMBER = 'recharge_add_phone_number';
export const RECHARGE_SELECT_COUNTRY = 'recharge_select_country';
export const RECHARGE_SELECT_AMOUT = 'recharge_select_amount';

export const RECHARGE_PROCESS_PAYMENT = 'recharge_process_payment';
export const RECHARGE_PROCESS_PAYMENT_SUCCESSFULLY =
  'recharge_process_payment_successfully';
export const RECHARGE_PROCESS_PAYMENT_FAILED =
  'recharge_process_payment_failed';

export const RECHARGE_PROCESS_TOPUP = 'recharge_process_topup';
export const RECHARGE_PROCESS_TOPUP_SUCCESSFULLY =
  'recharge_process_topup_successfully';
export const RECHARGE_PROCESS_TOPUP_FAILED = 'recharge_process_topup_failed';
export const RECHARGE_PROCESS_TOPOP_INSUFICIENT_CREDIT =
  'recharge_process_topup_insuficient_credit';

export const RECHARGE_COMPLETE = 'recharge_complete';
