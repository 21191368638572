import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from '../../utils/stringHelper';
import {
  rechargeAmountReceivedAdd,
  rechargeAmountSentAdd,
  rechargeFeeLoading,
  rechargeFeesAdd,
  rechargeFeesResponse,
  rechargeRatesAdd,
  rechargeTotalAmountAdd,
  rechargeUserCommission,
} from './rechargeSlice';
import { getFeesByAmount } from '../../api/server/fees.server.api';
import { get } from 'lodash';
import { APPLICATION_ID } from '../../constant/variables';
import { SUCCESS } from '../logs/logStatus';

const RechargeAmount = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const recharge = useSelector((state) => state.recharge);
  const productId = get(recharge, 'productId', '');

  const [message, setErrorMessage] = useState('');
  const [amount, setAmount] = useState('');
  const [debouncedAmount, setDebouncedAmount] = useState('');

  // Debounce effect to update the amount state properly
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedAmount(amount);
    }, 300); // Waits 300ms before updating

    return () => clearTimeout(handler);
  }, [amount]);

  useEffect(() => {
    const computeFee = async () => {
      if (isEmpty(debouncedAmount)) {
        return;
      }

      if (!/^\d+(\.\d+)?$/.test(debouncedAmount)) {
        setErrorMessage('Please enter a valid amount.');
        handleReset();
        return;
      }

      if (debouncedAmount <= 0) {
        setErrorMessage('Amount must be greater than 0.');
        handleReset();
        return;
      }

      if (debouncedAmount > 50) {
        setErrorMessage('Maximum allowed amount is $50.');
        handleReset();
        return;
      }

      dispatch(rechargeAmountSentAdd(debouncedAmount));
      const responseData = await handleComputeFeeByAmount(debouncedAmount);
      if (isEmpty(responseData)) {
        handleReset();
        return;
      }
      dispatch(rechargeFeesResponse(responseData));
      const totalFee = get(responseData, 'totalFee', 0);
      const totalAmount = get(responseData, 'totalAmount', 0);
      let amountReceived = get(responseData, 'amountReceiver', 0);
      let userCommission = get(responseData, 'commissionFee');
      const receiverRate = get(responseData, 'receiverRate', 0);
      dispatch(rechargeAmountReceivedAdd(amountReceived));
      dispatch(rechargeTotalAmountAdd(totalAmount.toFixed(2)));
      dispatch(rechargeFeesAdd(totalFee.toFixed(2)));
      dispatch(rechargeRatesAdd(receiverRate));
      dispatch(rechargeUserCommission(userCommission.toFixed(2)));
    };

    computeFee();
  }, [debouncedAmount]);

  const handleComputeFeeByAmount = async (amount) => {
    try {
      const operatorUid = get(recharge, 'operatorUid', '');
      const receiverCurrencyCode = get(recharge, 'receiverCurrencyCode', '');
      const currencyCode = get(user, 'data.currencyCode', '');
      if (
        !amount ||
        !productId ||
        !operatorUid ||
        !receiverCurrencyCode ||
        !currencyCode
      ) {
        return;
      }
      dispatch(rechargeFeeLoading(true));
      const requestData = {
        productId,
        operatorId: operatorUid,
        receiverCurrencyCode,
        senderCurrencyCode: currencyCode,
        applicationId: APPLICATION_ID.JLRSPACE,
        amount: Number(amount),
      };
      const responseData = await getFeesByAmount(requestData);
      if (!isEmpty(responseData?.data) && responseData?.status === SUCCESS) {
        return get(responseData, 'data.data', {});
      }
    } catch (error) {
      setErrorMessage('Unknown error. Contact support@jlrecharge.com');
    } finally {
      dispatch(rechargeFeeLoading(false));
    }
  };

  const handleRechargeAmount = async (event) => {
    const amountValue = get(event, 'target.value', '');
    setAmount(amountValue);
    setErrorMessage('');
  };

  const handleReset = () => {
    dispatch(rechargeAmountReceivedAdd(0));
    dispatch(rechargeTotalAmountAdd(''));
    dispatch(rechargeFeesAdd(''));
    dispatch(rechargeRatesAdd(0));
    dispatch(rechargeUserCommission(''));
    dispatch(rechargeFeesResponse(''));
  };

  if (
    isEmpty(recharge.phone) ||
    isEmpty(recharge.operatorName) ||
    isEmpty(recharge.productId)
  ) {
    return null;
  }

  return (
    <div className="mt-10">
      <label
        htmlFor="price"
        className="block text-gray-700 text-lg font-semibold tracking-wide"
      >
        Amount
      </label>
      <div className="mt-1 relative rounded-md shadow-md">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span className="text-gray-500 sm:text-base font-bold">$</span>
        </div>
        <input
          value={amount}
          type="text"
          name="amount"
          id="amount"
          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 border-gray-50 rounded-md py-4 sm:text-lg font-bold text-gray-600"
          placeholder="0.00"
          aria-describedby="price-currency"
          onChange={handleRechargeAmount}
        />
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <span
            className="text-gray-500 font-bold sm:text-base"
            id="price-currency"
          >
            {user?.data?.countryCode}
          </span>
        </div>
      </div>
      <p className="text-xs font-bold text-red-600">{message}</p>
    </div>
  );
};

export default RechargeAmount;
