import axios from 'axios';

export const SUCCESS = 'success';
export const ERROR = 'error';
export const OK = 200;

class Response {
  constructor(status, data, error) {
    this.status = status;
    // error message
    this.error = error;
    // data is null if error
    this.data = data;
  }
}

export function handleHttpError(error) {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    return new Response(ERROR, error.response.data, `Server error: ${error.response.status}`);
  }
  if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    return new Response(ERROR, undefined, 'No response from server.');
  }
  // Something happened in setting up the request that triggered an Error
  return new Response(ERROR, undefined, error.message);
}

export class HttpCommunication {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
    this.httpClient = axios.create({
      baseURL: this.baseUrl,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  async Get(node, headers) {
    try {
      const response = await this.httpClient.get(node, headers);
      if (response.status !== OK) {
        return new Response(ERROR, response.data, response.data.error);
      }
      return new Response(SUCCESS, response.data);
    } catch (error) {
      return handleHttpError(error);
    }
  }

  async Post(node, data, headers) {
    try {
      const response = await this.httpClient.post(node, data, headers);
      if (response.status !== OK) {
        return new Response(ERROR, response.data, response.data.error);
      }
      return new Response(SUCCESS, response.data);
    } catch (error) {
      return handleHttpError(error);
    }
  }
}
