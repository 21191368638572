import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
} from 'firebase/auth';
import {
  AuthCode,
  AuthMessage,
  AuthResponse,
} from '../../features/authentication/models';
import { EMAIL_VERIFIED, LOGIN } from '../../router/routeNames';

export default class FirebaseAuth {
  static async SignIn(authData) {
    try {
      const auth = getAuth();
      const authUser = await signInWithEmailAndPassword(
        auth,
        authData.email,
        authData.password
      );
      return new AuthResponse(authUser.user);
    } catch (error) {
      return new AuthResponse({
        status: AuthCode.FAILED,
        code: error.code,
        message: AuthMessage[error.code],
      });
    }
  }

  static async SignUp(authData) {
    try {
      const auth = getAuth();
      const authUser = await createUserWithEmailAndPassword(
        auth,
        authData.email,
        authData.password
      );
      await sendEmailVerification(authUser.user, {
        url: `${window.location.origin}${EMAIL_VERIFIED}/${authUser.user.uid}`,
      });

      return new AuthResponse(authUser.user);
    } catch (error) {
      return new AuthResponse({
        status: AuthCode.FAILED,
        code: error.code,
        message: AuthMessage[error.code],
      });
    }
  }

  static async SignOut() {
    try {
      const auth = getAuth();
      await signOut(auth);
      return {};
    } catch (error) {
      return new AuthResponse({
        status: AuthCode.FAILED,
        code: error.code,
        message: AuthMessage[error.code],
      });
    }
  }

  static async SendResetPasswordEmailLink(email) {
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email, {
        url: `${window.location.origin}${LOGIN}`,
      });
      return true;
    } catch (error) {
      return false;
    }
  }
}
