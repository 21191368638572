import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  PROTECTED_VIEW_SUCCESS,
  PROTECTED_VIEWS_FAILED,
  PROTECTED_VIEWS_PENDING,
  PROTECTED_VIEWS_UNKNOWN,
} from './protectedViewsStatus';
import FirestoreProtectedViews from '../../api/firebase/firebase.protectedViews';

const initialState = {
  status: PROTECTED_VIEWS_UNKNOWN,
  data: null,
  error: null,
};

export const protectedViewsCreate = createAsyncThunk(
  'protectedViews/create',
  async (protectedViewData, { rejectWithValue }) => {
    try {
      const protectedView = await FirestoreProtectedViews.CreateOrUpdate(
        protectedViewData
      );
      if (!protectedView) {
        return rejectWithValue('Failed to create protected view');
      }
      return protectedView;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const protectedSlice = createSlice({
  name: 'protectedViews',
  initialState,
  reducers: {
    protectedViewsStatusUpdate(state, action) {
      state.status = action.payload;
    },
    protectedViewAdd(state, action) {
      state.status = PROTECTED_VIEW_SUCCESS;
      state.data = action.payload;
    },
    protectedViewModelStatus(state, action) {
      return { ...state, protectedModelStatus: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(protectedViewsCreate.pending, (state) => {
        state.status = PROTECTED_VIEWS_PENDING;
        state.error = null;
      })
      .addCase(protectedViewsCreate.rejected, (state, action) => {
        state.status = PROTECTED_VIEWS_FAILED;
        state.error = action.payload || 'An error occurred';
        state.data = null;
      })
      .addCase(protectedViewsCreate.fulfilled, (state, action) => {
        if (action.payload) {
          state.status = PROTECTED_VIEW_SUCCESS;
          state.data = action.payload;
        } else {
          state.status = PROTECTED_VIEWS_FAILED;
          state.error = 'Failed to create protected view';
          state.data = null;
        }
      });
  },
});

export const {
  protectedViewsStatusUpdate,
  protectedViewAdd,
  protectedViewModelStatus,
} = protectedSlice.actions;
export default protectedSlice.reducer;
