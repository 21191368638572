import React from 'react';
import PropTypes from 'prop-types';
import { UsersIcon } from '@heroicons/react/outline';

const AgentState = ({ title, description }) => {
  return (
    <div className="flex  flex-col justify-center border-dashed rounded-lg p-12  hover:border-gray-40">
      <div className="flex justify-center mb-5">
        <UsersIcon className="h-20 w-20 text-blue-1000" aria-hidden="true" />
      </div>
      <div className="flex flex-col justify-center mb-5">
        <span className="text-gray-900 font-bold text-lg text-center mb-5">
          {title}
        </span>
        <span className="text-gray-500 font-semibold text-center">
          {description}
        </span>
      </div>
    </div>
  );
};

AgentState.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default AgentState;
