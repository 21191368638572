import {
  createEntityAdapter,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';
import { SETTINGS_UNKNOWN } from './settingsStatus';

const settingsAdapter = createEntityAdapter({
  selectId: (setting) => setting.uid,
});

const initialState = settingsAdapter.getInitialState({
  status: SETTINGS_UNKNOWN,
});

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    settingsStatus(state, action) {
      state.status = action.payload;
    },
    settingsAddMany: settingsAdapter.addMany,
    settingsAddOne: settingsAdapter.addOne,
  },
});

export default settingsSlice.reducer;

export const { settingsStatus, settingsAddMany, settingsAddOne } =
  settingsSlice.actions;

export const { selectAll: selectSettings, selectById: selectSettingById } =
  settingsAdapter.getSelectors((state) => state.settings);

export const selectSettingsIds = createSelector(selectSettings, (settings) =>
  settings.map((setting) => setting.uid)
);

export const selectSettingsByType = createSelector(
  // All Settings
  selectSettings,

  // Pass Settings Type Argument
  (_, type) => type,

  (settings, type) => settings.filter((setting) => setting.type === type)[0]
);
