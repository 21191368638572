import {
  getFirestore,
  collection,
  getDocs,
  query,
  where
} from 'firebase/firestore';

import firebaseInit from './firebase.index';

const app = firebaseInit();
const db = getFirestore(app);

export default class FirestoreProducts {
  static async LoadProducts() {
    try {
      const productCollection = [];
      const productQuery = query(
        collection(db, 'products'),
        where('productType', 'in', ['bundles', 'recharge'])
      );
      const productsSnapShot = await getDocs(productQuery);
      productsSnapShot.forEach((snapshot) => {
        productCollection.push(snapshot.data());
      });
      return productCollection;
    } catch (error) {
      return null;
    }
  }
}
