// Plugins: https://hypeserver.github.io/react-date-range/#calendar

import React, { useState } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { DateRange } from 'react-date-range';
import { CalendarIcon } from '@heroicons/react/solid';
import { addDays, subDays } from 'date-fns';

import { useDispatch } from 'react-redux';
import {
  filterDateAdd,
  filterDateEndAdd,
  filterDateStartAdd,
} from './filterSlice';

const DateRangeFilter = () => {
  const [hideCalendar, setHideCalendar] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date().toDateString());

  const [dateRange, setDateRange] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: addDays(new Date(), 0),
      key: 'Selection',
    },
  ]);

  const dispatch = useDispatch();

  const handleSelectCalendarDate = (date) => {
    const { Selection } = date;
    setDateRange([Selection]);
    setSelectedDate(new Date(Selection.endDate).toDateString());
    setHideCalendar(true);
    dispatch(filterDateAdd(new Date(date).toDateString()));

    dispatch(filterDateEndAdd(new Date(Selection.endDate).toDateString()));
    dispatch(filterDateStartAdd(new Date(Selection.startDate).toDateString()));
  };
  const renderCalendar = () => {
    return (
      <div>
        <DateRange
          moveRangeOnFirstSelection={true}
          retainEndDateOnFirstSelection={false}
          ranges={dateRange}
          onChange={(item) => handleSelectCalendarDate(item)}
        />
      </div>
    );
  };

  const renderCalendarCTA = () => {
    return (
      <div>
        <div className="mt-1 flex rounded-md shadow-sm">
          <div className="relative flex items-stretch flex-grow focus-within:z-10">
            <button
              onClick={() => setHideCalendar(!hideCalendar)}
              className="inline-flex items-center p-4 border rounded-2xl border-gray-300 shadow-sm text-sm leading-4 font-medium 
                                    text-gray-500 bg-white  focus:outline-none focus:ring-2 focus:ring-offset-2"
            >
              <CalendarIcon
                className="-ml-0.5 mr-2 h-4 w-4 text-gray-500"
                aria-hidden="true"
              />
              {selectedDate || 'Select Date'}
            </button>
          </div>
        </div>
      </div>
    );
  };

  return hideCalendar ? renderCalendarCTA() : renderCalendar();
};

export default DateRangeFilter;
