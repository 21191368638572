import { getAuth, onAuthStateChanged } from 'firebase/auth';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { initAppCheck } from './api/firebase/firebase.appcheck';

import firebaseInit from './api/firebase/firebase.index';
import FirestoreCountries from './api/firebase/firestore.countries';
import FirestoreRates from './api/firebase/firestore.rates';
import FirestoreSettings from './api/firebase/firestore.settings';
import FirestoreUser from './api/firebase/firestore.user';
import { authenticationAdd } from './features/authentication/authenticationSlice';
import { AUTHENTICATION_SUCESS } from './features/authentication/authState';
import {
  countryAddMany,
  countryStatus,
} from './features/countries/countriesSlice';
import { COUNTRY_LOADED } from './features/countries/countryStatus';
import { ratesAddMany } from './features/rates/ratesSlice';
import { settingsAddMany } from './features/settings/settingsSlice';
import { userAdd } from './features/user/userSlice';
import AuthenticatedRouter from './router/AuthenticatedRouter';
import PublicRouter from './router/PublicRouter';
import {
  DASHBOARD,
  EMAIL_VERIFICATION,
  EMAIL_VERIFIED,
  RECHARGE,
  PERSONAL_INFORMATION,
} from './router/routeNames';
import { ROLES } from './features/authentication/models';

const app = firebaseInit();
const auth = getAuth(app);
window.initializedAppCheck = false;
initAppCheck(app);

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const authentication = useSelector((state) => state.authentication);

  const handleOnAuthStateChanged = async (user) => {
    if (user) {
      let userData = await FirestoreUser.LoadById(user.uid);
      if (!userData?.email) {
        userData = {
          ...userData,
          email: user.email,
        };
      }
      if (userData === null) {
        return;
      }
      dispatch(authenticationAdd(userData));
      dispatch(userAdd(userData));

      if (!user.emailVerified) {
        navigate(EMAIL_VERIFICATION, { replace: true });
        return;
      }

      const countries = await FirestoreCountries.LoadAll();
      if (countries !== null && countries.length > 0) {
        dispatch(countryAddMany(countries));
        dispatch(countryStatus(COUNTRY_LOADED));
      }

      const settings = await FirestoreSettings.LoadAll();
      if (settings !== undefined && settings.length > 0) {
        dispatch(settingsAddMany(settings));
      }

      const rates = await FirestoreRates.LoadAll();
      if (rates !== undefined && rates.length > 0) {
        dispatch(ratesAddMany(rates));
      }

      if (location.pathname === EMAIL_VERIFIED + '/' + userData.uid) {
        return;
      }

      // Navigate to User Personal Information
      if (!userData?.isActive) {
        navigate(PERSONAL_INFORMATION, { replace: true });
        return;
      }

      const route =
        userData.roles[ROLES.manager] || userData.roles[ROLES.partner]
          ? DASHBOARD
          : RECHARGE;
      navigate(route, { replace: true });
    }
  };

  useEffect(() => {
    const unsubiscreber = onAuthStateChanged(auth, handleOnAuthStateChanged);
    return () => {
      return unsubiscreber();
    };
  }, [authentication.status]);

  return authentication.status === AUTHENTICATION_SUCESS ? (
    <AuthenticatedRouter />
  ) : (
    <PublicRouter />
  );
}

export default App;
