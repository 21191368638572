import { getAuth } from 'firebase/auth';
import { HttpCommunication } from '../io/httpClient';
import { getAppCheckToken } from '../firebase/firebase.appcheck';

export const ResponseCode = {
  SUCCESS: 'success',
  ERROR: 'error',
};

export class ServerApi {
  constructor() {
    const url = process.env.REACT_APP_API_HOST;
    this.Communication = new HttpCommunication(url);
  }

  async Post(node, data) {
    const userToken = await getAuth().currentUser?.getIdToken();
    const { token } = await getAppCheckToken(window.appCheck);
    return this.Communication.Post(node, JSON.stringify(data), {
      headers: {
        Authorization: `Bearer ${userToken}`,
        'X-Firebase-AppCheck': token,
      },
    });
  }

  async Get(node) {
    try {
      const userToken = await getAuth().currentUser?.getIdToken();
      const { token } = await getAppCheckToken(window.appCheck);
      return this.Communication.Get(node, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          'X-Firebase-AppCheck': token,
        },
      });
    } catch (error) {
      return null;
    }
  }
}
