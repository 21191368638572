import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit';
import { COUNTRY_UNKNOWN } from './countryStatus';

const contriesAdapter = createEntityAdapter({
  selectId: (country) => country.isoName,
});

const initialState = contriesAdapter.getInitialState({
  status: COUNTRY_UNKNOWN,
});

export const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    countryStatus(state, action) {
      state.status = action.payload;
    },
    countryAddMany: contriesAdapter.addMany,
  },
});

export const { countryStatus, countryAddMany } = countriesSlice.actions;

export default countriesSlice.reducer;

export const { selectAll: selectCountries, selectById: selectCountryById } =
  contriesAdapter.getSelectors((state) => state.countries);

export const selectCountriesIds = createSelector(selectCountries, (countries) =>
  countries.map((country) => country.isoName)
);

export const selectedCountryByIsoName = createSelector(
  // Arg 1: Select Countries selector function
  selectCountries,

  // Arg 2: Parament isoName
  (_, isoName) => isoName,

  // Func: Received Resulted from Arg 1 and Arg2.
  (countries, isoName) =>
    countries.filter((country) => country.isoName === isoName)[0]
);

export const selectCountryReceiverActive = createSelector(
  // Argument 1: select countries  selector function
  selectCountries,

  // Simple version of this fuction will be
  // (countries) => countries.filter((country) => country.isActive === true)
  (countries) => {
    const allActiveCountries = countries.filter((country) => {
      if (country.isActive) {
        return country;
      }
    });

    return allActiveCountries;
  }
);
