import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';

const exportToExcel = async (columns, data, sheetName) => {
  const workbook = new Workbook();
  try {
    workbook.creator = 'JLRecharge';
    workbook.created = new Date();

    //Set Date to System Date
    workbook.properties.date1904 = true;

    // Add Sheet
    const workSheet = workbook.addWorksheet(sheetName);
    workSheet.state = 'visible';

    workSheet.columns = columns;

    workSheet.addRows(data);
    // Write to the buffer
    const buff = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buff]), `${sheetName}.xlsx`);
  } catch (error) {
    return error;
  } finally {
    workbook.removeWorksheet(sheetName);
  }
};

export { exportToExcel };
