import React, { useSelector } from 'react-redux';
import { compose } from 'redux';
import withBoostrapApp from '../../components/HOC/withBoostrapApp';
import withSidebar from '../../components/HOC/withSidebar';
import BusinessState from '../../features/business/BusinessState';
import { BUSINESS_READY } from '../../features/business/businessStatus';
import DateRangeFilter from '../../features/filters/DateRangeFilter';
import OperatorFilter from '../../features/filters/OperatorFilter';
import TransactionsStatsCollection from '../../features/transactions/TransactionsStatsCollection';
import WalletState from '../../features/wallets/WalletState';
import { WALLETS_READY } from '../../features/wallets/walletStatus';
import { isEmpty } from '../../utils/stringHelper';
import AutoRechargeConfirmation from '../../features/wallets/AutoRechargeConfirmation';

const Dashboard = () => {
  const wallet = useSelector((state) => state.wallet);
  const business = useSelector((state) => state.businesses);

  if (business.status === BUSINESS_READY && business?.ids.length === 0) {
    return <BusinessState />;
  }

  if (
    wallet.status === WALLETS_READY &&
    isEmpty(wallet?.data?.paymentMethodId)
  ) {
    return (
      <WalletState
        title="Add Payment Method"
        description="First time here! Setup Payment Method to sent credit to your loves ones."
      />
    );
  }

  return (
    <div>
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            Dashboard
          </h2>
          <AutoRechargeConfirmation />
        </div>
        <div className="mt-4 flex md:mt-0">
          <OperatorFilter />
          <DateRangeFilter />
        </div>
      </div>
      <TransactionsStatsCollection />
    </div>
  );
};

export default compose(withBoostrapApp, withSidebar)(Dashboard);
