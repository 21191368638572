import React from 'react';
import { CurrencyDollarIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';

const WalletFund = ({ amount, currency }) => {
  return (
    <div className="relative z-0 flex overflow-hidden bg-white rounded-3xl flex-1 justify-center py-10 mb-10">
      <div className="rounded-full border-4 border-blue-1000 flex flex-col justify-center w-40 h-40 text-center">
        <CurrencyDollarIcon
          className="text-blue-1000 mt-5  h-10 w-10 self-center"
          aria-hidden="true"
        />
        <p className="text-3xl font-bold text-blue-1000  mt-2">
          {Number(amount).toFixed(2) || 0.0}
        </p>
        <p className="text-xl font-bold text-blue-1000 ">{currency}</p>
      </div>
    </div>
  );
};

WalletFund.defaultProps = {
  currency: 'USD',
};

WalletFund.propTypes = {
  amount: PropTypes.number.isRequired,
  currency: PropTypes.string,
};
export default WalletFund;
