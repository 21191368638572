import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { compose } from 'redux';
import FirestoreTransactions from '../../api/firebase/firestore.transactions';
import withBoostrapApp from '../../components/HOC/withBoostrapApp';
import withSidebar from '../../components/HOC/withSidebar';
import { transactionsAddMany } from '../../features/transactions/transactionsSlice';
import TransactionCollection from '../../features/transactions/TransactionCollection';
import AutoRechargeConfirmation from '../../features/wallets/AutoRechargeConfirmation';

const Transactions = () => {
  const dispatch = useDispatch();
  const authentication = useSelector((state) => state.authentication);

  const loadTransactions = async () => {
    const transactions = await FirestoreTransactions.LoadByUserId(
      authentication.data?.uid
    );
    dispatch(transactionsAddMany(transactions));
  };

  useEffect(() => {
    loadTransactions();
  }, []);

  return (
    <div>
      <div className="md:flex md:items-center md:justify-between mb-4">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            Transactions History
          </h2>
        </div>
      </div>
      <div className="mb-6">
        <AutoRechargeConfirmation />
      </div>
      <div className="flex-1 relative z-0 flex overflow-hidden bg-white rounded-3xl">
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <div className="inset-0 py-6 px-4 sm:px-4 lg:px-6">
            <div className="rounded-lg p-8">
              <TransactionCollection />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default compose(withBoostrapApp, withSidebar)(Transactions);
