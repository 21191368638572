import React from 'react';
import Svg from '../../assets/svg';

function OnBoardingLogoHeader() {
  return (
    <div className="flex items-center py-4 bg-white">
      <img
        src={Svg.logo}
        alt="Logo"
        className="object-contain ml-4 sm:ml-6 lg:ml-8 w-[150px] h-auto sm:w-[180px] lg:w-[203px]"
      />
    </div>
  );
}

export default OnBoardingLogoHeader;
