import { getFirestore, collection, getDocs } from 'firebase/firestore';

import firebaseInit from './firebase.index';

const app = firebaseInit();
const db = getFirestore(app);

export default class FirestoreSettings {
  static async LoadAll() {
    try {
      const settingsCollection = [];
      const settingsSnapShot = await getDocs(collection(db, 'settings'));
      if (settingsSnapShot === null || settingsSnapShot.length === 0) {
        return settingsCollection;
      }

      settingsSnapShot.forEach((snapshot) => {
        settingsCollection.push(snapshot.data());
      });
      return settingsCollection;
    } catch (error) {
      return null;
    }
  }
}
