import React from 'react';
import { DownloadIcon } from '@heroicons/react/outline';
import { useSelector } from 'react-redux';
import { exportToExcel } from '../../api/io/fileSystem';
import { selectBusinessSelected } from '../business/businessSlice';

const columns = [
  { header: 'Reference', key: 'Reference' },
  { header: 'Date', key: 'Date' },
  { header: 'Operator', key: 'Operator' },
  { header: 'Phone', key: 'Phone' },
  { header: 'SellingPrice', key: 'SellingPrice' },
  { header: 'CostPrice', key: 'CostPrice' },
  { header: 'Commission', key: 'Commission' },
];

const CtaDownload = () => {
  const transactionEntities = useSelector(
    (state) => state.transactions.entities
  );
  const transactions = Object.values(transactionEntities);
  const business = useSelector((state) => selectBusinessSelected(state));
  const handleDownloadReport = () => {
    const allCvmovelTransactions = [];
    transactions.forEach((transaction) => {
      allCvmovelTransactions.push({
        Reference: transaction.reference,
        Date: new Date(
          (transaction.date.seconds +
            transaction.date.nanoseconds * 0.00000001) *
            1000
        ).toDateString(),
        Operator: transaction.operatorName,
        Phone: transaction.phone,
        SellingPrice: Number(transaction.amountSent).toFixed(2),
        CostPrice:
          Number(transaction.amountSent) - Number(transaction.userCommission),
        Commission: transaction.userCommission,
      });
    });

    exportToExcel(columns, allCvmovelTransactions, business.name);
  };

  return (
    <div>
      <div className="mt-1 flex rounded-md shadow-sm mx-2">
        <div className="relative flex items-stretch flex-grow focus-within:z-10">
          <button
            onClick={handleDownloadReport}
            className="inline-flex items-center p-4 border rounded-2xl border-gray-300 shadow-sm text-sm leading-4 font-medium 
                                    text-gray-500 bg-white  focus:outline-none focus:ring-2 focus:ring-offset-2"
          >
            <DownloadIcon
              className="-ml-0.5 mr-2 h-4 w-4 text-gray-500"
              aria-hidden="true"
            />
            Download Report
          </button>
        </div>
      </div>
    </div>
  );
};

export default CtaDownload;
