import React, { useEffect } from 'react';
import { compose } from 'redux';
import withSidebar from '../../components/HOC/withSidebar';
import withBoostrapApp from '../../components/HOC/withBoostrapApp';
import BusinessCollection from '../../features/business/BusinessCollection';
import { useDispatch, useSelector } from 'react-redux';
import { selectBusinessSelected } from '../../features/business/businessSlice';
import FirestoreTransactions from '../../api/firebase/firestore.transactions';
import {
  transactionsAddMany,
  transactionsStatus,
} from '../../features/transactions/transactionsSlice';
import { TRANSACTION_PROCESSING_COMPLETE } from '../../features/transactions/transactionState';
import { Link } from 'react-router-dom';
import { BUSINESS_ADD } from '../../router/routeNames';
import AutoRechargeConfirmation from '../../features/wallets/AutoRechargeConfirmation';

const Business = () => {
  const dispatch = useDispatch();
  const business = useSelector((state) => selectBusinessSelected(state));

  useEffect(() => {
    async function loadBusinessData() {
      if (business === undefined) return;
      const transactions = await FirestoreTransactions.LoadByBusinessId(
        business.uid
      );
      if (transactions !== null && transactions.length > 0) {
        dispatch(transactionsAddMany(transactions));
        dispatch(transactionsStatus(TRANSACTION_PROCESSING_COMPLETE));
      }
    }

    loadBusinessData();
  }, [business?.uid]);

  return (
    <div className="font-Poppins">
      <div className="md:flex md:items-center md:justify-between mb-4">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            My Business
          </h2>
        </div>
        <Link
          to={BUSINESS_ADD}
          className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium 
                        text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Add Business
        </Link>
      </div>
      <div className="mb-6">
        <AutoRechargeConfirmation />
      </div>
      <div className="flex-1 flex-col lg:flex-row relative z-0 flex overflow-hidden bg-white rounded-3xl">
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <div className="inset-0 py-6 px-4 sm:px-4 lg:px-6">
            <div className="rounded-lg p-8">
              <BusinessCollection />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default compose(withBoostrapApp, withSidebar)(Business);
