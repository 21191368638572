import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';

import firebaseInit from './firebase.index';

const app = firebaseInit();
const db = getFirestore(app);

export default class FirestoreBusiness {
  static async LoadByOwnerId(userId) {
    try {
      const businessCollection = [];
      const queryBusinessess = query(
        collection(db, 'businesses'),
        where('ownerId', '==', userId)
      );

      const businessesSnapShot = await getDocs(queryBusinessess);

      businessesSnapShot.forEach((snapshot) => {
        businessCollection.push(snapshot.data());
      });
      return businessCollection;
    } catch (error) {
      return null;
    }
  }

  static async LoadById(businessId) {
    try {
      const userDocRef = doc(db, 'businesses', businessId);

      const userDocSnap = await getDoc(userDocRef);

      return userDocSnap.exists() ? userDocSnap.data() : null;
    } catch (error) {
      return null;
    }
  }

  static async LoadByAgentId(userId) {
    try {
      const businessCollection = [];
      const queryBusinessess = query(
        collection(db, 'businesses'),
        where('agents', 'array-contains', userId)
      );

      const businessesSnapShot = await getDocs(queryBusinessess);

      businessesSnapShot.forEach((snapshot) => {
        businessCollection.push(snapshot.data());
      });
      return businessCollection;
    } catch (error) {
      return null;
    }
  }

  static async Create(business) {
    try {
      const newBusiness = business;
      const pmRef = doc(collection(db, 'businesses'));

      newBusiness.uid = pmRef.id;
      newBusiness.date = serverTimestamp();

      await setDoc(pmRef, { ...newBusiness }, { merge: true });
      return newBusiness;
    } catch (error) {
      return null;
    }
  }

  static async Selected(businessData) {
    try {
      const businessCollection = [];
      const queryBusinessess = query(
        collection(db, 'businesses'),
        where('ownerId', '==', businessData.ownerId)
      );

      const businessesSnapShot = await getDocs(queryBusinessess);

      const promisseCollection = [];
      businessesSnapShot.forEach(async (snapshot) => {
        const docRef = doc(db, 'businesses', snapshot.id);
        const promise = updateDoc(docRef, {
          selected: snapshot.id === businessData.uid ? true : false,
        });
        businessCollection.push(snapshot.data());
        promisseCollection.push(promise);
      });
      Promise.all(promisseCollection);
      return businessCollection;
    } catch (error) {
      return null;
    }
  }

  static async Update(businessData) {
    try {
      const ref = doc(db, 'businesses', businessData.uid);
      await setDoc(ref, businessData, { merge: true });
      return businessData;
    } catch (error) {
      return null;
    }
  }
}
