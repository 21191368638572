import { ROLES } from '../authentication/models';

export const totalRechargeSold = (transactionCollection) => {
  // Transactions Collection Validation
  if (
    transactionCollection === null ||
    transactionCollection === undefined ||
    transactionCollection.length === 0
  )
    return 0;

  let totalRechargeSold = 0;
  transactionCollection.forEach((transation) => {
    // Convert Amount Received to USD Dollar
    totalRechargeSold = totalRechargeSold + transation.amountSent;
  });
  return totalRechargeSold;
};

export const ComputeCommission = (totalRechargeSold, role, settings) => {
  // totalRechargeSold Validation
  if (totalRechargeSold <= 0) return 0;

  // Role Validation
  if (role === null || role === undefined) return 0;

  //settings Validation
  if (settings === null || settings === undefined) return 0;

  let commissionPercentage = 0;

  switch (role.toLowerCase()) {
    case ROLES.manager:
      commissionPercentage = settings.managerCommission;
      break;
    case ROLES.partner:
      commissionPercentage = settings.partnerCommission;
      break;
    case ROLES.agent:
      commissionPercentage = settings.agentCommission;
      break;
  }

  // Operators Commissions

  const totalCommission = totalRechargeSold * 0.1;

  // Device commission by Roles

  return Number(totalCommission) * Number(commissionPercentage);
};
