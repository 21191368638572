import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useSelector, useDispatch } from 'react-redux';

import { walletPaymentMethodAdd, walletsStatus } from './walletSlice';
import Log from '../../models/log';
import {
  WALLETS_ADD_PAYMENTMETHOD_FAILED,
  WALLETS_READY,
} from './walletStatus';
import FirestoreLogs from '../../api/firebase/firestore.logs';
import {
  paymentMethodsAdd,
  paymentMethodStatus,
} from '../paymentMethods/paymentMethodsSlice';
import {
  PAYMENTMETHODS_ADD_FAILED,
  PAYMENTMETHODS_ADD_PENDING,
  PAYMENTMETHODS_READY,
} from '../paymentMethods/paymentMethodState';
import {
  businessUpdate,
  selectBusinessSelected,
} from '../business/businessSlice';
import { isEmpty } from '@firebase/util';
import { WALLET } from '../../router/routeNames';

const AddDebitCard = () => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);
  const business = useSelector((state) => selectBusinessSelected(state));

  const [error, setError] = useState('');
  const [cardNumberElement, setCardNumberElement] = useState(undefined);

  const [countryName, setCountryName] = useState(user?.data?.country);
  const [streetAddress, setStreetAddress] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [nameOnCard, setNameOnCard] = useState('');

  var elementStyles = {
    base: {
      color: '#374151',
      fontWeight: 100,
      fontSize: '14px',
      ':focus': {
        color: '#424770',
      },

      '::placeholder': {
        color: '#6B7280',
      },

      ':focus::placeholder': {
        color: '#CFD7DF',
      },
    },
    invalid: {
      color: '#6B7280',
      ':focus': {
        color: '#FA755A',
      },
      '::placeholder': {
        color: '#FFCCA5',
      },
    },
  };

  var elementClasses = {
    focus: 'focus',
    empty: 'empty',
    invalid: 'invalid',
  };

  const handleNameOnCard = (data) => {
    setNameOnCard(data.target.value);
  };

  const handleStreetAddress = (data) => {
    setStreetAddress(data.target.value);
  };

  const handleCountryName = (data) => {
    setCountryName(data.target.value);
  };

  const handleCreatePaymentMethod = async () => {
    try {
      dispatch(paymentMethodStatus(PAYMENTMETHODS_ADD_PENDING));
      const paymentMethodResponse = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumberElement,
        billing_details: {
          address: {
            postal_code: postalCode,
            country: user?.data?.countryCode,
            line1: streetAddress,
          },
          name: nameOnCard,
          phone: user?.data?.phoneNumber || null,
          email: user?.data?.email || null,
        },
      });
      if (paymentMethodResponse.error) {
        setError(paymentMethodResponse.error.message);
        dispatch(paymentMethodStatus(PAYMENTMETHODS_ADD_FAILED));
        return;
      }

      const newDebitCard = {
        walletId: user.data.uid,
        name: nameOnCard,
        brand: paymentMethodResponse.paymentMethod.card.brand,
        country: paymentMethodResponse.paymentMethod.card.country,
        costomerId: user.data.stripeId,
        expMonth: paymentMethodResponse.paymentMethod.card.exp_month,
        expYear: paymentMethodResponse.paymentMethod.card.exp_year,
        funding: paymentMethodResponse.paymentMethod.card.funding,
        last4: paymentMethodResponse.paymentMethod.card.last4,
        paymentMethodId: paymentMethodResponse.paymentMethod.id,
        stripeId: user.data.stripeId,
        userId: user.data.uid,
        isDefault: false,
        type: 'card',
        amount: 0,
        businessId: business?.uid,
        fullName: nameOnCard,
        postalCode:
          paymentMethodResponse.paymentMethod.billing_details.address
            .postal_code,
        addressLine1: streetAddress,
      };
      dispatch(
        walletPaymentMethodAdd({
          uid: user.data.uid,
          paymentMethodId: paymentMethodResponse.paymentMethod.id,
        })
      );
      dispatch(paymentMethodsAdd(newDebitCard));
      dispatch(walletsStatus(WALLETS_READY));
      dispatch(paymentMethodStatus(PAYMENTMETHODS_READY));

      // Ensure business has wallet
      if (isEmpty(business.walletId)) {
        const businessData = {
          uid: business.uid,
          walletId: user.data.uid,
        };
        dispatch(businessUpdate(businessData));
      }

      navigate(WALLET);
    } catch (error) {
      const log = new Log({
        userId: user.data.uid,
        contact: user.data.email || user.data.phoneNumber,
        message: 'Failed Add new Wallet',
        action: 'Component.AddDebitCard',
        exception: error.message || error,
        type: WALLETS_ADD_PAYMENTMETHOD_FAILED,
      });
      await FirestoreLogs.Add(log);
    }
  };

  const handleDebitCardChange = (data) => {
    if (data.complete) {
      setPostalCode(data.value.postalCode);
    }
  };
  useEffect(() => {
    const debitcard =
      elements.getElement('card') ||
      elements.create('card', {
        style: elementStyles,
        classes: elementClasses,
      });
    debitcard.mount('#debitCard');
    setCardNumberElement(debitcard);
    debitcard.on('change', handleDebitCardChange);
  }, []);

  return (
    <div className="px-8 pb-5">
      <div className="flex flex-row justify-between">
        <h2 className="text-gray-700 text-2xl font-semibold tracking-wide">
          Add Payment Method
        </h2>
      </div>
      <div className="mt-4 py-5 rounded-lg">
        <fieldset>
          <p className="text-red-600 text-sm">{error}</p>
          <legend className="block text-base font-medium text-gray-700">
            Card Details
          </legend>
          <div className="mt-1 bg-white rounded-md -space-y-px">
            <div>
              <label htmlFor="debitCard" className="sr-only">
                Debit Card
              </label>
              <div
                name="debitCard"
                id="debitCard"
                className="relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 sm:text-s h-11 
								border border-gray-200 border-dashed py-4 px-4"
              ></div>
            </div>
            <div>
              <label htmlFor="nameOnCard" className="sr-only">
                Name on Card
              </label>
              <input
                type="text"
                name="nameOnCard"
                id="nameOnCard"
                autoComplete="nameOnCard"
                className="relative block w-full rounded-none rounded-b-md bg-transparent focus:z-10 sm:text-sm h-11 
								border border-gray-200 border-dashed py-4 px-4"
                placeholder="Name on Card"
                value={nameOnCard}
                onChange={handleNameOnCard}
              />
            </div>
          </div>
        </fieldset>
        <fieldset className="mt-6 bg-white">
          <legend className="block text-base font-medium text-gray-700">
            Billing address
          </legend>
          <div className="mt-1 rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor=" Country" className="sr-only">
                Country
              </label>
              <input
                type="text"
                name="country"
                id="country"
                autoComplete="country-name"
                className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                placeholder="Country"
                value={countryName}
                onChange={handleCountryName}
              />
            </div>

            <div>
              <label htmlFor="street-address" className="sr-only">
                Street Address
              </label>
              <input
                type="text"
                name="street-address"
                id="street-address"
                autoComplete="street-address"
                className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full rounded-none  bg-transparent focus:z-10 sm:text-sm border-gray-300"
                placeholder="Street Address"
                value={streetAddress}
                onChange={handleStreetAddress}
              />
            </div>
          </div>
        </fieldset>
        <button
          onClick={handleCreatePaymentMethod}
          type="button"
          className="rounded-md w-full justify-center inline-flex items-center px-6 mt-2 py-3 border border-transparent text-base font-medium shadow-sm 
                                text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default AddDebitCard;
