import React from 'react';
import { TrendingUpIcon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';
import { StatsTypeIcons } from '../../common/dictionaryIcon';
import { Link } from 'react-router-dom';
import { WALLET_RECHARGE } from '../../router/routeNames';
const WalletStats = ({ title, data, type, minAmount }) => {
  const StatsIcon = StatsTypeIcons[type];
  const warning = data < minAmount;

  return (
    <div
      className={`relative ${
        warning ? 'bg-red-900' : 'bg-white'
      } mb-5 pt-5 px-4  sm:pt-6 sm:px-6 rounded-lg overflow-hidden`}
    >
      <div className="flex flex-col">
        <div>
          <StatsIcon
            className={`h-6 w-6 ${
              warning ? 'text-white' : 'text-blue-1000'
            } mb-2`}
            aria-hidden="true"
          />
        </div>
        <div className="flex flex-row">
          <p
            className={`mb-4 text-base ${
              warning ? 'text-white' : 'text-black'
            } text-3xl font-semibold mr-1`}
          >
            {data}
          </p>
          <TrendingUpIcon
            className={`h-6 w-6  ${warning ? 'text-white' : 'text-blue-1000'}`}
            aria-hidden="true"
          />
        </div>
        {warning ? (
          <Link
            className="mb-4 text-base bg-white py-1 px-2 rounded-md text-blue-1000 font-bold"
            to={WALLET_RECHARGE}
          >
            Add Balance
          </Link>
        ) : (
          <div
            className={`mb-4 text-base ${
              warning ? 'text-white' : 'text-black'
            }`}
          >
            {title}
          </div>
        )}
      </div>
    </div>
  );
};

WalletStats.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  data: PropTypes.number.isRequired,
  minAmount: PropTypes.number.isRequired,
};
export default WalletStats;
