import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
} from 'firebase/firestore';

import firebaseInit from './firebase.index';

const app = firebaseInit();
const db = getFirestore(app);

export default class FirestorePayments {
  static async LoadByUserId(userId) {
    try {
      const paymentCollection = [];
      const paymentQuery = query(
        collection(db, 'payments'),
        where('userId', '==', userId)
      );
      const paymentsSnapShot = await getDocs(paymentQuery);
      paymentsSnapShot.forEach((snapshot) => {
        paymentCollection.push(snapshot.data());
      });
      return paymentCollection;
    } catch (error) {
      return null;
    }
  }
}
