import React, { useState } from 'react';
import { EMAIL_VERIFIED, SIGNUP } from '../../router/routeNames';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth, sendEmailVerification } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import FirebaseAuth from '../../api/firebase/firebase.auth';
import { authenticationSignOut } from './authenticationSlice';
import Svg from '../../assets/svg';
import { get } from 'lodash';
import LoaderStats from '../../components/loader/LoaderStats';
import CopyrightText from '../../components/atoms/CopyrightText';

const AuthEmailVerification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const userData = get(user, 'data', {});
  const authentication = useSelector((state) => state.authentication);
  const authenticationData = get(authentication, 'data', {});
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false); // State to handle loading
  const userEmail = userData?.email || authenticationData?.email;

  const resendEmail = async () => {
    try {
      setLoading(true);
      const auth = getAuth();
      await sendEmailVerification(auth.currentUser, {
        url: `${window.location.origin}${EMAIL_VERIFIED}?email=${userData?.email}`
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setMessage(error.message);
    }
  };

  const tryAnotherEmail = async () => {
    const auth = getAuth().currentUser;
    if (auth) {
      await FirebaseAuth.SignOut();
      dispatch(authenticationSignOut(null));
    }
    navigate(SIGNUP, { replace: true });
    return;
  };

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <div className="mx-auto w-full max-w-sm lg:max-w-lg px-4 lg:px-0 mt-[60px]">
        <div className="mt-8">
          <div className="flex flex-col items-center">
            {' '}
            <div className="mb-6 flex justify-center">
              <img
                src={Svg.envelop}
                alt="Email Icon"
                className="object-contain h-20 w-20 lg:h-24 lg:w-24"
              />
            </div>
            <div className="flex flex-col justify-center mb-[30px]">
              <h1 className="text-center text-gray-900 text-2xl lg:text-3xl font-bold mb-[20px]">
                Verify your email
              </h1>

              <div className="w-full text-center">
                <span className="text-gray-500 text-base lg:text-lg font-medium tracking-tight">
                  A verification link has been sent to{' '}
                </span>
                <span className="text-indigo-600 text-base lg:text-lg font-medium tracking-tight">
                  {userEmail}
                </span>
                <span className="text-gray-500 text-base lg:text-lg font-medium tracking-tight">
                  {' '}
                  Open your email and click on the link to verify your account.
                </span>
              </div>
            </div>
            {message && (
              <p className="text-sm text-red-600 font-medium mb-[10px] text-center">
                {message}
              </p>
            )}
            <div className="mb-[16px] w-full flex justify-center">
              <div
                onClick={resendEmail}
                className={`w-full max-w-[436px] h-[50px] lg:h-[60px] p-2.5 rounded-xl flex justify-center items-center cursor-pointer ${
                  loading ? 'bg-gray-400' : 'bg-indigo-700 hover:bg-indigo-600'
                }`}
              >
                {loading ? (
                  <LoaderStats />
                ) : (
                  <div className="text-center text-white text-base lg:text-lg font-bold tracking-tight">
                    Request new link
                  </div>
                )}
              </div>
            </div>
            <div className="w-full text-center mb-[31px]">
              <div className="text-gray-500 text-base lg:text-lg font-medium leading-normal tracking-tight mb-[20px]">
                Didn’t get the email? Check your spam folder.
              </div>
              <div className="text-gray-500 text-base lg:text-lg font-medium leading-normal tracking-tight">
                Or
              </div>
            </div>
            <button
              onClick={tryAnotherEmail}
              className="w-full max-w-[436px] h-[50px] lg:h-[60px] p-2.5 rounded-xl border border-gray-200 flex justify-center items-center gap-2.5 text-indigo-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <div className="text-base lg:text-lg font-bold leading-normal tracking-tight">
                Try another email
              </div>
            </button>
          </div>
        </div>
      </div>
      <div>
        <CopyrightText />
      </div>
    </div>
  );
};

export default AuthEmailVerification;
