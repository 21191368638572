import { ServerApi } from './serverApi';

export const createAgent = async (request) => {
  try {
    const url = `${process.env.REACT_APP_API_HOST}/create`;
    const serverApi = new ServerApi();
    const agent = await serverApi.Post(url, request);
    return agent;
  } catch (error) {
    return null;
  }
};