import { get, isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import FirestorePayments from '../../api/firebase/firebase.payments';
import withBoostrapApp from '../../components/HOC/withBoostrapApp';
import withSidebar from '../../components/HOC/withSidebar';
import PaymentCollection from '../../features/payments/PaymentCollections';
import {
  paymentsAddMany,
  paymentStatus,
} from '../../features/payments/paymentsSlice';
import {
  PAYMENTS_LOADED_DONE,
  PAYMENTS_LOADED_FAILED,
  PAYMENTS_LOADED_PENDING,
} from '../../features/payments/paymentState';
import ProtectedViewCreateLockModel from '../../features/protectedViews/ProtectedViewCreateLockModel';
import ProtectedViewUnLockModel from '../../features/protectedViews/ProtectedViewUnLockModel';
import { PROTECTED_VIEW_CREATE_LOCK } from '../../features/protectedViews/protectedViewsStatus';

const Payments = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const protectedViews = useSelector((state) => state.protectedViews);
  const protectedModelStatus = get(protectedViews, 'protectedModelStatus', '');
  const isLocked = get(protectedViews, 'data.isLocked', false);

  useEffect(() => {
    async function loadPayments() {
      try {
        const userId = get(user, 'data.uid');
        if (isEmpty(userId)) {
          return;
        }

        dispatch(paymentStatus(PAYMENTS_LOADED_PENDING));
        const allPayments = await FirestorePayments.LoadByUserId(userId);
        if (allPayments && allPayments.length > 0) {
          dispatch(paymentsAddMany(allPayments));
          dispatch(paymentStatus(PAYMENTS_LOADED_DONE));
        } else {
          dispatch(paymentStatus(PAYMENTS_LOADED_FAILED));
        }
      } catch (execptions) {
        return;
      }
    }

    loadPayments();
  }, []);
  return (
    <div>
      <div className="md:flex md:items-center md:justify-between mb-10">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
          Payments
        </h2>
      </div>
      <div className="flex-1 relative z-0 flex overflow-hidden bg-white rounded-3xl">
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <div className="inset-0 py-6 px-4 sm:px-4 lg:px-6">
            <div className="rounded-lg p-8">
              {isLocked ? <ProtectedViewUnLockModel /> : <PaymentCollection />}
            </div>
          </div>
        </main>
      </div>
      {protectedModelStatus == PROTECTED_VIEW_CREATE_LOCK && (
        <ProtectedViewCreateLockModel />
      )}
    </div>
  );
};

export default compose(withBoostrapApp, withSidebar)(Payments);
