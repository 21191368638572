import {
  getFirestore,
  collection,
  getDocs,
  query,
  doc,
  setDoc,
  serverTimestamp,
  deleteDoc,
} from 'firebase/firestore';

import firebaseInit from './firebase.index';
import { isEmpty, map, get } from 'lodash';
const app = firebaseInit();
const db = getFirestore(app);

export default class FirestorePaymentMethod {
  static async LoadByWalletId(walletId) {
    try {
      const pmCollection = [];
      const pmQuery = query(
        collection(db, `wallets/${walletId}/paymentMethods`)
      );

      const walletsSnapShot = await getDocs(pmQuery);

      walletsSnapShot.forEach((snapshot) => {
        pmCollection.push(snapshot.data());
      });
      return pmCollection;
    } catch (error) {
      return null;
    }
  }

  static async Add(pmData) {
    try {
      const newPMData = pmData;
      const pmRef = doc(
        collection(db, `wallets/${pmData.walletId}/paymentMethods`)
      );

      newPMData.uid = pmRef.id;
      newPMData.date = serverTimestamp();
      await setDoc(pmRef, { ...newPMData }, { merge: true });

      return newPMData;
    } catch (error) {
      return null;
    }
  }

  static async DeleteById(paymentMethod) {
    try {
      await deleteDoc(
        doc(
          db,
          'wallets',
          `${paymentMethod.walletId}/paymentMethods/${paymentMethod.uid}`
        )
      );
      return paymentMethod.uid;
    } catch (error) {
      return null;
    }
  }

  static async migratePaymentMethods(userId) {
    try {
      // Validate the `userId` using lodash
      if (isEmpty(userId)) {
        return null;
      }

      // incorrectly named sub-collection under the user's wallet
      const oldPaymentMethodsRef = collection(
        db,
        `wallets/${userId}/paymentMehods`
      );
      const oldPaymentMethodsSnapshot = await getDocs(oldPaymentMethodsRef);

      // Check if the old sub-collection has any documents
      if (oldPaymentMethodsSnapshot.empty) {
        return null;
      }

      // Migrate each document to the correct sub-collection
      await Promise.all(
        map(oldPaymentMethodsSnapshot.docs, async (pmDoc) => {
          const pmData = pmDoc.data();
          const paymentMethodId = get(pmData, 'paymentMethodId', '');
          if (isEmpty(paymentMethodId)) {
            return;
          }

          const newPaymentMethodRef = doc(
            collection(db, `wallets/${userId}/paymentMethods`),
            paymentMethodId
          );

          await setDoc(newPaymentMethodRef, pmData, { merge: true });
        })
      );

      // Delete documents from the old sub-collection after migration
      await Promise.all(
        map(oldPaymentMethodsSnapshot.docs, (pmDoc) => {
          if (isEmpty(pmDoc.id)) {
            return;
          }
          const oldDocRef = doc(oldPaymentMethodsRef, pmDoc.id);
          return deleteDoc(oldDocRef);
        })
      );

      return true;
    } catch (error) {
      return null;
    }
  }
}
