import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit';
import { find, filter, isEmpty } from 'lodash';
import { PRODUCT_UNKNOWN } from './productsStatus';
const productsAdapter = createEntityAdapter({
  selectId: (product) => product.uid,
});

const initialState = productsAdapter.getInitialState({
  status: PRODUCT_UNKNOWN,
});

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    productsAddMany: productsAdapter.addMany,
    productsSetAll: productsAdapter.setAll,
    productsUpdate: productsAdapter.updateOne,
    productsAddOne: productsAdapter.addOne,
    productUpdate(state, action) {
      const { uid, changes } = action.payload;
      const existingProduct = state.entities[uid];
      if (existingProduct) {
        productsAdapter.updateOne(state, { id: uid, changes });
      }
    },
    productsStatus(state, action) {
      state.status = action.payload;
    },
  },
});

export default productsSlice.reducer;

export const {
  productsAddMany,
  productsSetAll,
  productsStatus,
  productsUpdate,
  productsAddOne,
  productUpdate,
} = productsSlice.actions;

export const { selectAll: selectProducts, selectById: selectProductById } =
  productsAdapter.getSelectors((state) => state.products);

export const selectProductsIds = createSelector(selectProducts, (products) =>
  products.map((product) => product.uid)
);

export const selectProductsByOperatorId = createSelector(
  // First Argument - Selector function to return all products
  selectProducts,

  // Second Argument - Selector function accepts operatorId to filter products
  (_, operatorId) => operatorId,

  // Logic to filter products by operatorId and always prioritize recharge product
  (products, operatorId) => {
    // Filter products by matching operatorId
    const productResult = filter(products, { operatorId });

    // Always prioritize the product with type 'recharge'
    const rechargeProduct = find(productResult, { productType: 'recharge' });
    if (rechargeProduct) {
      // Place the recharge product at the first index, followed by others
      return [
        rechargeProduct,
        ...filter(
          productResult,
          ({ productType }) => productType !== 'recharge'
        ),
      ];
    }

    // Return the filtered product list
    return isEmpty(productResult) ? [] : productResult;
  }
);
