import { getAuth, getIdToken } from 'firebase/auth';
import { getAppCheckToken } from '../firebase/firebase.appcheck';
import { HttpCommunication } from '../io/httpClient';


export const StripeCode = {
  CARD_DECLINED: 'card_declined',
  INSUFFICIENT_FUNDS: 'insufficient_funds',
  LOST_CARD: 'lost_card',
  EXPIRATION_CARD: 'expired_card',
  INCORRECT_CVC: 'incorrect_cvc',
  INCORRECT_ZIP: 'incorrect_zip',
  PROCESSING_ERROR: 'processing_error',
  INCORRECT_NUMBER: 'incorrect_number',
  REQUIRED_ACTION: 'requires_action',
  REQUIRES_CAPTURE: 'requires_capture',
  PAYMENT_INTENT_AUTHENTICATION_FAILURE:
    'payment_intent_authentication_failure',
};

export const StripeCodeMessage = {
  insufficient_funds: 'Insufficient Funds.',
  card_declined: 'Your Card was declined.',
  lost_card: 'Card Report losted.',
  expired_card: 'Your Card is expired.',
  incorrect_cvc: 'Your CVC is incorrect.',
  processing_error: 'Error processing your card.',
  incorrect_number: 'Card number is incorrect.',
  '': 'Issue processing your card.',
};

export const responseMessage = (code, declineCode) => {
  let codeMessage = 'Your Card was declined.';
  switch (code) {
    case StripeCode.CARD_DECLINED:
      if (declineCode === StripeCode.INSUFFICIENT_FUNDS) {
        codeMessage = 'Insufficient Funds.';
      } else {
        codeMessage = 'Your Card was declined.';
      }
      break;
    case StripeCode.LOST_CARD:
      codeMessage = 'Card Report losted.';
      break;
    case StripeCode.EXPIRATION_CARD:
      codeMessage = 'Your Card is expired.';
      break;
    case StripeCode.INCORRECT_CVC:
      codeMessage = 'Your CVC is incorrect.';
      break;
    case StripeCode.INCORRECT_NUMBER:
      codeMessage = 'Card number is incorrect.';
      break;
    case StripeCode.INCORRECT_ZIP:
      codeMessage = 'Your Postal Code is incorrect.';
      break;
    case StripeCode.INSUFFICIENT_FUNDS:
      codeMessage = 'Insufficient Funds.';
      break;
    case StripeCode.PAYMENT_INTENT_AUTHENTICATION_FAILURE:
      codeMessage =
        'There was an issue when trying to reload your wallet. Please fix it so we can auto reload your wallet.';
      break;
    default:
      codeMessage = 'Your Card was declined.';
      break;
  }
  return codeMessage;
};

export class StripeClient {
  constructor(url) {
    this.Communication = new HttpCommunication(url);
  }
  async Post(node, data) {
    const auth = getAuth();
    const { currentUser } = auth;
    const userToken = await getIdToken(currentUser, true);

    const { token } = await getAppCheckToken();
    return this.Communication.Post(node, data, {
      headers: {
        Authorization: `Bearer ${userToken}`,
        'X-Firebase-AppCheck': token,
      },
    });
  }
}
