import React from 'react';
import UpdatePassword from '../../features/authentication/UpdatePassword';

const NewPassword = () => {
  return (
    <div className="flex flex-col min-h-screen justify-center text-center">
      <UpdatePassword />
    </div>
  );
};

export default NewPassword;
